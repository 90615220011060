// ==============================
export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_FAILURE = "GET_PACKAGES_FAILURE";
// ==============================
// ==============================
export const GET_FEATURES = "GET_FEATURES";
export const GET_FEATURES_SUCCESS = "GET_FEATURES_SUCCESS";
export const GET_FEATURES_FAILURE = "GET_FEATURES_FAILURE";
// ==============================
