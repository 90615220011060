import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  addBlogReviewFailure,
  addBlogReviewSuccess,
  deleteBlogReviewFailure,
  deleteBlogReviewSuccess,
  getBlogsByArchiveFailure,
  getBlogsByArchiveSuccess,
  getBlogsByDateFailure,
  getBlogsByDateSuccess,
  getBlogsByTagFailure,
  getBlogsByTagSuccess,
  getBlogsCategoriesFailure,
  getBlogsCategoriesSuccess,
  getBlogsCategoryBySlugFailure,
  getBlogsCategoryBySlugSuccess,
  getBlogsFailure,
  getBlogsSuccess,
  getBlogsTagsFailure,
  getBlogsTagsSuccess,
  getSingleBlogFailure,
  getSingleBlogSuccess,
  loadMoreBlogsFailure,
  loadMoreBlogsSuccess,
} from "./actions";
import {
  ADD_BLOG_REVIEW,
  DELETE_BLOG_REVIEW,
  GET_BLOGS,
  GET_BLOGS_BY_ARCHIVE,
  GET_BLOGS_BY_DATE,
  GET_BLOGS_BY_TAG,
  GET_BLOGS_CATEGORIES,
  GET_BLOGS_CATEGORY_BY_SLUG,
  GET_BLOGS_TAGS,
  GET_SINGLE_BLOG,
  LOAD_MORE_BLOGS,
} from "./actionTypes";
import {
  addBlogReviewApi,
  deleteBlogReviewApi,
  getBlogsApi,
  getBlogsArchive,
  getBlogsByDateApi,
  getBlogsByTagApi,
  getBlogsCategoriesApi,
  getBlogsCategoryBySlugApi,
  getBlogsTagsApi,
  getSingleBlogApi,
} from "@/api/blogs";
import { FormattedMessage } from "react-intl";

function* getBlogs({ payload }) {
  try {
    const { data } = yield call(getBlogsApi, payload);
    yield put(getBlogsSuccess(data));
  } catch (error) {
    yield put(getBlogsFailure(JSON.stringify(error)));
  }
}

//=======================================================
//=======================================================

function* getBlogsByArchive({ payload }) {
  try {
    const { data } = yield call(getBlogsArchive, payload);
    yield put(getBlogsByArchiveSuccess(data));
  } catch (error) {
    yield put(getBlogsByArchiveFailure(JSON.stringify(error)));
  }
}

//=======================================================
//=======================================================

function* getBlogsByDate({ payload }) {
  try {
    const { data } = yield call(getBlogsByDateApi, payload);
    yield put(getBlogsByDateSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getBlogsByDateFailure(JSON.stringify(error)));
  }
}

//=======================================================
//=======================================================

function* getBlogsCategories({ payload }) {
  try {
    const { data } = yield call(getBlogsCategoriesApi, payload);
    yield put(getBlogsCategoriesSuccess(data?.blogCategories));
  } catch (error) {
    yield put(getBlogsCategoriesFailure(JSON.stringify(error)));
  }
}

//=======================================================
//=======================================================

function* getBlogsCategoryBySlug({ payload }) {
  try {
    const { data } = yield call(getBlogsCategoryBySlugApi, payload);
    yield put(getBlogsCategoryBySlugSuccess(data?.category));
  } catch (error) {
    yield put(getBlogsCategoryBySlugFailure(JSON.stringify(error)));
  }
}

//=======================================================
//=======================================================

function* getBlogsByTag({ payload }) {
  try {
    const { data } = yield call(getBlogsByTagApi, payload);
    yield put(getBlogsByTagSuccess(data));
  } catch (error) {
    yield put(getBlogsByTagFailure(JSON.stringify(error)));
  }
}

//=======================================================
//=======================================================

function* getSingleBlog({ payload }) {
  try {
    const { data } = yield call(getSingleBlogApi, payload);
    yield put(getSingleBlogSuccess(data?.blog));
  } catch (error) {
    yield put(getSingleBlogFailure(JSON.stringify(error)));
  }
}

//=======================================================
//=======================================================

function* getBlogsTags({ payload }) {
  try {
    const { data } = yield call(getBlogsTagsApi, payload);
    yield put(getBlogsTagsSuccess(data.tags));
  } catch (error) {
    yield put(getBlogsTagsFailure(JSON.stringify(error)));
  }
}

//=======================================================
//=======================================================

function* addBlogReview({ payload }) {
  try {
    const { data, success } = yield call(addBlogReviewApi, payload);
    yield put(addBlogReviewSuccess(data?.blog));
    if (success) {
      yield payload.reset();
      yield payload?.toast.success(<FormattedMessage id="commentAdded" />);
    }
  } catch (error) {
    console.log(error);
    yield put(addBlogReviewFailure(JSON.stringify(error)));
  }
}

//=======================================================
//=======================================================

function* deleteBlogReview({ payload }) {
  try {
    yield call(deleteBlogReviewApi, payload);
    yield put(deleteBlogReviewSuccess(payload));

    yield payload.resetInputs();
  } catch (error) {
    yield put(deleteBlogReviewFailure(JSON.stringify(error)));
  }
}

//=======================================================
//=======================================================

function* loadMoreBlogsSaga({ payload }) {
  try {
    const { data } = yield call(getBlogsApi, payload);
    yield put(loadMoreBlogsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(loadMoreBlogsFailure(JSON.stringify(error)));
  }
}

//=======================================================
//=======================================================

export function* watchBlogsSaga() {
  yield takeEvery(GET_BLOGS, getBlogs);
}
export function* watchBlogsByDateSaga() {
  yield takeEvery(GET_BLOGS_BY_DATE, getBlogsByDate);
}
export function* watchBlogsByArchiveSaga() {
  yield takeEvery(GET_BLOGS_BY_ARCHIVE, getBlogsByArchive);
}
export function* watchBlogsCategoriesSaga() {
  yield takeEvery(GET_BLOGS_CATEGORIES, getBlogsCategories);
}
export function* watchBlogsCategoryBySlugSaga() {
  yield takeEvery(GET_BLOGS_CATEGORY_BY_SLUG, getBlogsCategoryBySlug);
}
export function* watchBlogsByTagSaga() {
  yield takeEvery(GET_BLOGS_BY_TAG, getBlogsByTag);
}
export function* watchSingleBlogSaga() {
  yield takeEvery(GET_SINGLE_BLOG, getSingleBlog);
}
export function* watchBlogsTagsSaga() {
  yield takeEvery(GET_BLOGS_TAGS, getBlogsTags);
}
export function* watchAddBlogReviewSaga() {
  yield takeLatest(ADD_BLOG_REVIEW, addBlogReview);
}
export function* watchDeleteBlogReviewSaga() {
  yield takeLatest(DELETE_BLOG_REVIEW, deleteBlogReview);
}

export function* watchLoadMoreBlogsSaga() {
  yield takeLatest(LOAD_MORE_BLOGS, loadMoreBlogsSaga);
}

function* blogsSaga() {
  yield all([fork(watchBlogsSaga)]);
  yield all([fork(watchBlogsByDateSaga)]);
  yield all([fork(watchBlogsByArchiveSaga)]);
  yield all([fork(watchBlogsCategoriesSaga)]);
  yield all([fork(watchBlogsCategoryBySlugSaga)]);
  yield all([fork(watchBlogsByTagSaga)]);
  yield all([fork(watchSingleBlogSaga)]);
  yield all([fork(watchBlogsTagsSaga)]);
  yield all([fork(watchAddBlogReviewSaga)]);
  yield all([fork(watchDeleteBlogReviewSaga)]);
  yield all([fork(watchLoadMoreBlogsSaga)]);
}
export default blogsSaga;
