import server from "./server";

export const postUserLoginApi = async ({ cookies, formData }) => {
  const response = await server({ cookies }).post("/auth/login", formData);
  return response.data;
};
export const postUserRegisterApi = async ({ cookies, formData }) => {
  const response = await server({ cookies }).post("/auth/register", formData);
  return response.data;
};
export const forgotPasswordApi = async ({ cookies, formData }) => {
  const response = await server({ cookies }).post(
    "/auth/resendPassword",
    formData
  );
  return response.data;
};
export const verifyOTPApi = async ({ cookies, formData }) => {
  const response = await server({ cookies }).post(
    "/auth/verifyRegisterOTP",
    formData
  );
  return response.data;
};
export const resendVerifyOTPApi = async ({ cookies, formData }) => {
  const response = await server({ cookies }).post(
    "/auth/ResendVerificationCode",
    formData
  );
  return response.data;
};
export const updatePasswordApi = async ({ cookies, formData }) => {
  const response = await server({ cookies }).post(
    "/auth/updatePassword",
    formData
  );
  return response.data;
};
