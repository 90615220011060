import { HYDRATE } from "next-redux-wrapper";
import {
  GET_PORTAL_SETTINGS,
  GET_PORTAL_SETTINGS_SUCCESS,
  GET_PORTAL_SETTINGS_FAILURE,
  GET_SEO_DATA,
  GET_SEO_DATA_SUCCESS,
  GET_SEO_DATA_FAILURE,
} from "./actionTypes";

const initialState = {
  portalSettings: [],
  seoData: {},
  error: "",
  loading: false,
};
const portalSettings = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      for (const key in action.payload?.portalSettings) {
        if (Object.hasOwnProperty.call(action.payload?.portalSettings, key)) {
          const element = action.payload?.portalSettings[key];
          element === "init" && delete action.payload?.portalSettings[key];
        }
      }

      return { ...state, ...action.payload.portalSettings };
    // ========== START : portalSettings ===========

    case GET_PORTAL_SETTINGS:
      return {
        ...state,
        loading: true,
      };

    case GET_PORTAL_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        portalSettings: action.payload,
      };

    case GET_PORTAL_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START : SEO DATA ===========
    case GET_SEO_DATA:
      return {
        ...state,
        loading: true,
      };

    case GET_SEO_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        seoData: action.payload,
      };

    case GET_SEO_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default portalSettings;
