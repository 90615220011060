import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getPortalSettingsFailure,
  getPortalSettingsSuccess,
  getSeoDataFailure,
  getSeoDataSuccess,
} from "./actions";
import { GET_PORTAL_SETTINGS, GET_SEO_DATA } from "./actionTypes";
import { getPortalSettingsApi } from "@/api/portalSettings";
import { getSeoDataApi } from "@/api/portalSection";

function* getPortalSettingsSaga({ payload }) {
  try {
    const { data } = yield call(getPortalSettingsApi, payload);
    yield put(getPortalSettingsSuccess(data?.settings));
  } catch (error) {
    yield put(getPortalSettingsFailure(JSON.stringify(error)));
  }
}
// ==============================================
function* getSeoDataSaga({ payload }) {
  try {
    const { data } = yield call(getSeoDataApi, payload);
    yield put(getSeoDataSuccess(data?.portalSection));
  } catch (error) {
    yield put(getSeoDataFailure(JSON.stringify(error)));
  }
}
// ==============================================

export function* watchPortalSettingsSaga() {
  yield takeEvery(GET_PORTAL_SETTINGS, getPortalSettingsSaga);
}

export function* watchSeoDataSaga() {
  yield takeEvery(GET_SEO_DATA, getSeoDataSaga);
}

function* portalSettingsSaga() {
  yield all([fork(watchPortalSettingsSaga)]);
  yield all([fork(watchSeoDataSaga)]);
}
export default portalSettingsSaga;
