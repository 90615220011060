import { combineReducers } from "redux";
import blogs from "./blogs/reducer";
import authentication from "./auth/reducer";
import portalSection from "./portalSection/reducer";
import portalSettings from "./portalSettings/reducer";
import packages from "./packages/reducer";
import themes from "./themes/reducer";
import helpCenter from "./helpCenter/reducer";

export default combineReducers({
  authentication,
  blogs,
  portalSection,
  portalSettings,
  packages,
  themes,
  helpCenter,
});
