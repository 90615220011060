import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getCategoriesFailure,
  getCategoriesSuccess,
  getThemesFailure,
  getThemesSuccess,
} from "./actions";
import { getCategoriesApi, getThemesApi } from "../../api/themes";
import { GET_CATEGORIES, GET_THEMES } from "./actionTypes";

function* getCategoriesSaga({ payload }) {
  try {
    const { data } = yield call(getCategoriesApi, payload);
    yield put(getCategoriesSuccess(data?.categories));
  } catch (error) {
    yield put(getCategoriesFailure(JSON.stringify(error)));
  }
}
function* getThemesSaga({ payload }) {
  try {
    const { data } = yield call(getThemesApi, payload);
    yield put(getThemesSuccess(data?.themes));
  } catch (error) {
    yield put(getThemesFailure(JSON.stringify(error)));
  }
}

export function* watchCategoriesSaga() {
  yield takeEvery(GET_CATEGORIES, getCategoriesSaga);
}
export function* watchThemesSaga() {
  yield takeEvery(GET_THEMES, getThemesSaga);
}

function* themesSaga() {
  yield all([fork(watchCategoriesSaga)]);
  yield all([fork(watchThemesSaga)]);
}
export default themesSaga;
