import { HYDRATE } from "next-redux-wrapper";
import {
  GET_HELP_CATEGORIES_BY_TYPE,
  GET_HELP_CATEGORIES_BY_TYPE_SUCCESS,
  GET_HELP_CATEGORIES_BY_TYPE_FAILURE,
  POST_TECHNICAL_SUPPORTS,
  POST_TECHNICAL_SUPPORTS_SUCCESS,
  POST_TECHNICAL_SUPPORTS_FAILURE,
  GET_HELP_CENTER_CONTENT_BY_ID,
  GET_HELP_CENTER_CONTENT_BY_ID_SUCCESS,
  GET_HELP_CENTER_CONTENT_BY_ID_FAILURE,
  GET_PORTAL_HELP_BLOGS,
  GET_PORTAL_HELP_BLOGS_SUCCESS,
  GET_PORTAL_HELP_BLOGS_FAILURE,
  ADD_VOTE,
  ADD_VOTE_SUCCESS,
  ADD_VOTE_FAILURE,
  ADD_SUGGESTION,
  ADD_SUGGESTION_SUCCESS,
  ADD_SUGGESTION_FAILURE,
  GET_HELP_CATEGORIES,
  GET_HELP_CATEGORIES_SUCCESS,
  GET_HELP_CATEGORIES_FAILURE,
  GET_HELP_BLOG,
  GET_HELP_BLOG_SUCCESS,
  GET_HELP_BLOG_FAILURE,
  ADD_COMMENT_IN_SUGGESTION,
  ADD_COMMENT_IN_SUGGESTION_SUCCESS,
  ADD_COMMENT_IN_SUGGESTION_FAILURE,
  GET_PORTAL_FAQS,
  GET_PORTAL_FAQS_SUCCESS,
  GET_PORTAL_FAQS_FAILURE,
  ADD_PORTAL_SUBSCRIBERS,
  ADD_PORTAL_SUBSCRIBERS_SUCCESS,
  ADD_PORTAL_SUBSCRIBERS_FAILURE,
  POST_PORTAL_RESERVATION,
  POST_PORTAL_RESERVATION_SUCCESS,
  POST_PORTAL_RESERVATION_FAILURE,
  GET_HELP_BLOGS_BY_CATEGORY,
  GET_HELP_BLOGS_BY_CATEGORY_SUCCESS,
  GET_HELP_BLOGS_BY_CATEGORY_FAILURE,
} from "./actionTypes";
import { getId } from "@/helpers/functions";

const initialState = {
  portalHelpBlogs: [],
  portalFaqs: [],
  helpCategoriesByType: [],
  helpCategories: [],
  // helpBlogsByType: [],
  helpBlog: {},
  helpCenterContentById: [],
  error: "",
  loading: false,
  loadingSubscription: false,
  customLoading: false,
};
const helpCenter = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      for (const key in action.payload?.helpCenter) {
        if (Object.hasOwnProperty.call(action.payload?.helpCenter, key)) {
          const element = action.payload?.helpCenter[key];
          element === "init" && delete action.payload?.helpCenter[key];
        }
      }

      return { ...state, ...action.payload.helpCenter };
    // ========== START : Portal Help Blogs ===========

    case GET_PORTAL_HELP_BLOGS:
      return {
        ...state,
        loading: true,
      };

    case GET_PORTAL_HELP_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        portalHelpBlogs: action.payload,
        helpCenterContentById: action.payload,
      };

    case GET_PORTAL_HELP_BLOGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START : Help Blogs By Category ===========

    case GET_HELP_BLOGS_BY_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case GET_HELP_BLOGS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        helpCenterContentById: action.payload,
      };

    case GET_HELP_BLOGS_BY_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // // ========== START : Get HelpCenter Blogs By Type ===========
    // case GET_PORTAL_HELP_BLOGS:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    // case GET_PORTAL_HELP_BLOGS_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     helpBlogsByType: action.payload,
    //   };

    // case GET_PORTAL_HELP_BLOGS_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };
    // ========== START : Help Categories By Type ===========

    case GET_HELP_CATEGORIES_BY_TYPE:
      return {
        ...state,
        loading: true,
      };

    case GET_HELP_CATEGORIES_BY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        helpCategoriesByType: action.payload,
      };

    case GET_HELP_CATEGORIES_BY_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START : PORTAL FAQS ===========

    case GET_PORTAL_FAQS:
      return {
        ...state,
        loading: true,
      };

    case GET_PORTAL_FAQS_SUCCESS:
      return {
        ...state,
        loading: false,
        portalFaqs: action.payload,
      };

    case GET_PORTAL_FAQS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START : General Settings ===========

    case GET_HELP_CATEGORIES:
      return {
        ...state,
        loading: true,
      };

    case GET_HELP_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        helpCategories: action.payload,
      };

    case GET_HELP_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ========== START : SINGLE SUGGESTION ===========

    case GET_HELP_BLOG:
      return {
        ...state,
        loading: true,
        customLoading: true,
      };

    case GET_HELP_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        helpBlog: action.payload,
        customLoading: false,
      };

    case GET_HELP_BLOG_FAILURE:
      return {
        ...state,
        loading: false,
        customLoading: false,
        error: action.payload,
      };
    // ========== START : GET HELP CENTER CONTENT BY ID ===========

    case GET_HELP_CENTER_CONTENT_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case GET_HELP_CENTER_CONTENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        helpCenterContentById: action.payload,
      };

    case GET_HELP_CENTER_CONTENT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START : POST TECHNICAL SUPPORTS ===========

    case POST_TECHNICAL_SUPPORTS:
      return {
        ...state,
        loading: true,
      };

    case POST_TECHNICAL_SUPPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        // suggestions: action.payload,
      };

    case POST_TECHNICAL_SUPPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START : POST PORTAL RESERVATION ===========

    case POST_PORTAL_RESERVATION:
      return {
        ...state,
        loading: true,
      };

    case POST_PORTAL_RESERVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        // suggestions: action.payload,
      };

    case POST_PORTAL_RESERVATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START :  ADD VOTE ===========

    case ADD_VOTE:
      return {
        ...state,
        loading: true,
      };

    case ADD_VOTE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedContent = state.helpCenterContentById.map((item) => {
        if (getId(item) === getId(action.payload)) {
          return {
            ...item,
            votes: action.payload.votes,
          };
        }
        return item;
      });

      return {
        ...state,
        loading: false,
        helpCenterContentById: updatedContent,
        helpBlog: action.payload,
      };

    case ADD_VOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START :  ADD SUGGESTION ===========

    case ADD_SUGGESTION:
      return {
        ...state,
        loading: true,
      };

    case ADD_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        helpCenterContentById: [...state.helpCenterContentById, action.payload],
      };

    case ADD_SUGGESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ============================
    case ADD_COMMENT_IN_SUGGESTION:
      return {
        ...state,
        loading: true,
      };
    case ADD_COMMENT_IN_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        helpBlog: action.payload,
      };

    case ADD_COMMENT_IN_SUGGESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ============================
    case ADD_PORTAL_SUBSCRIBERS:
      return {
        ...state,
        loading: true,
        loadingSubscription: true,
      };
    case ADD_PORTAL_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingSubscription: false,
      };

    case ADD_PORTAL_SUBSCRIBERS_FAILURE:
      return {
        ...state,
        loading: false,
        loadingSubscription: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default helpCenter;
