import Link from "next/link";
import dynamic from "next/dynamic";
import Image from "next/future/image";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { addPortalSubscribers } from "@/store/actions";
import styles from "./styles/style.module.scss";
// Import Components
const ScrollTopButton = dynamic(
  () => import("@/components/Shared/SrcollToTop/ScrollToTop"),
  {
    ssr: false,
  }
);
// Import Images And SVGs
import CallIcon from "@/assets/svgs/call.svg";
import Img1 from "@/assets/images/vector1.png";
import SmsIcon from "@/assets/svgs/sms-tracking.svg";
// Import Social Media Icons
import FacebookImage from "@/assets/svgs/socialMedia/facebook.svg";
import LinkedImage from "@/assets/svgs/socialMedia/linked.svg";
import PinterestImage from "@/assets/svgs/socialMedia/pentrest.svg";
import TwitterImage from "@/assets/svgs/socialMedia/twitter.svg";
import YoutubeImage from "@/assets/svgs/socialMedia/youtube.svg";
import TiktokImage from "@/assets/svgs/socialMedia/tiktok.svg";
import InstagramImage from "@/assets/svgs/socialMedia/instagram.svg";
import WhatsupImage from "@/assets/svgs/socialMedia/whatsup.svg";
import SnapchatImage from "@/assets/svgs/socialMedia/snapchat.svg";

const Footer = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();

  const { portalSettings } = useSelector((state) => state.portalSettings);
  const { loadingSubscription } = useSelector((state) => state.helpCenter);
  const socialData = portalSettings?.find((item) => item?._id === "social");
  const mainData = portalSettings?.find((item) => item?._id === "main");
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(
      addPortalSubscribers({
        data,
        reset,
        toast,
        cookies: {},
      })
    );
  };

  const phoneNumber = socialData?.items?.find(
    (item) => item.title === "phone"
  )?.value;
  const email = socialData?.items?.find(
    (item) => item.title === "email"
  )?.value;

  const footerLinks = [
    {
      href: "/",
      ariaLabel: "Home",
      messageId: "home",
    },
    {
      href: "/help-center",
      ariaLabel: "helpCenter",
      messageId: "helpCenter",
    },
    {
      href: "/blog",
      ariaLabel: "Blog",
      messageId: "blog",
    },
  ];

  const renderSocialLink = (title, IconComponent, urlPrefix = "") => {
    const socialItem = socialData?.items?.find((item) => item.title === title);
    return socialItem ? (
      <a
        href={`${urlPrefix}${socialItem.value}`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={title}
      >
        <IconComponent />
      </a>
    ) : null;
  };

  return (
    <div className={`${styles["main-footer"]} main-footer`}>
      <Container>
        <div className="footer-top">
          <Row>
            <Col xs={12} md={12} lg={12} xl={4}>
              <div className="first-col">
                <div className="footer-logo">
                  <Link href="/">
                    <a className="logo">
                      <Image
                        src={
                          mainData?.items?.find(
                            (item) => item.title === "logo_color"
                          )?.value
                        }
                        alt="logoIcon"
                        width={172}
                        height={52}
                        priority={false}
                        loading="lazy"
                      />
                      {/* <div className="logo-light">
                        <Logo />
                      </div>
                      <div className="logo-dark">
                        <LogoDark />
                      </div> */}
                    </a>
                  </Link>
                </div>
                <div className="desc">
                  <p>
                    {locale === "ar"
                      ? mainData?.items?.find(
                          (item) => item.title === "description_ar"
                        )?.value
                      : mainData?.items?.find(
                          (item) => item.title === "description_en"
                        )?.value}
                  </p>
                </div>
                <div className="contact">
                  <ul>
                    <li>
                      <CallIcon />
                      <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                    </li>
                    <li>
                      <SmsIcon />
                      <a href={`mailto:${email}`}>{email}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col xs={12} md={12} lg={12} xl={4}>
              <div className="footer-content">
                <Row>
                  <Col xs={6} md={6} lg={6} xl={6}>
                    <div>
                      <div className="footer-head-title">
                        <h3>
                          <FormattedMessage id="importantLinks" />
                        </h3>
                      </div>
                      <ul className="all-desc-footerItem">
                        {footerLinks?.map(
                          ({ href, ariaLabel, messageId }, index) => (
                            <li key={index}>
                              <Link href={href}>
                                <a className="desc-path" aria-label={ariaLabel}>
                                  <FormattedMessage id={messageId} />
                                </a>
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </Col>

                  <Col xs={6} md={6} lg={6} xl={6}>
                    <div>
                      <div className="footer-head-title">
                        <h3>
                          <FormattedMessage id="importantPages" />
                        </h3>
                      </div>
                      <ul className="all-desc-footerItem">
                        <li>
                          <Link href="/terms">
                            <a
                              className="desc-path"
                              aria-label="Terms and Conditions"
                            >
                              <FormattedMessage id="termsAndConditions" />
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href="/policy">
                            <a
                              className="desc-path"
                              aria-label="privacy policy"
                            >
                              <FormattedMessage id="privacyPolicy" />
                            </a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} md={12} lg={12} xl={4}>
              <div className="footer-email">
                <div className="footer-head-title">
                  <h3>
                    <FormattedMessage id="mailingList" />
                  </h3>
                </div>
                <div className="desc">
                  <p>
                    {locale === "ar"
                      ? mainData?.items?.find(
                          (item) => item.title === "newLetterDescription_ar"
                        )?.value
                      : mainData?.items?.find(
                          (item) => item.title === "newLetterDescription_en"
                        )?.value}
                  </p>
                </div>
                <div className="input-div">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                      type="email"
                      placeholder={formatMessage({
                        id: "enterYourEmailHere",
                      })}
                      {...register("email", {
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: formatMessage({
                            id: "invalidEmail",
                          }),
                        },
                      })}
                      aria-label="Email"
                      autoComplete="email"
                    />

                    <button
                      type="submit"
                      aria-label="Subscribe"
                      className={`subscribeBtn flex-center ${
                        !watch("email")?.trim()?.length ? "disabled" : ""
                      }`}
                      disabled={!watch("email")?.trim()?.length}
                    >
                      {loadingSubscription ? (
                        <Spinner />
                      ) : (
                        <FormattedMessage id="subscribe" />
                      )}
                    </button>
                  </form>
                </div>
                {errors?.email && (
                  <p className="error">
                    <span>{errors.email.message}</span>
                    {/* <span>
                      <FormattedMessage id="emailIsRequired" />
                    </span> */}
                  </p>
                )}
                <div className="social-media">
                  {renderSocialLink("linkedin", LinkedImage)}
                  {renderSocialLink("pinterest", PinterestImage)}
                  {renderSocialLink("twitter", TwitterImage)}
                  {renderSocialLink("facebook", FacebookImage)}
                  {renderSocialLink("Youtube", YoutubeImage)}
                  {renderSocialLink("tiktok", TiktokImage)}
                  {renderSocialLink("instagram", InstagramImage)}
                  {renderSocialLink("whatsApp", WhatsupImage, "https://wa.me/")}
                  {renderSocialLink(
                    "snapchat",
                    SnapchatImage,
                    "https://www.snapchat.com/add/"
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <div className="footer-bottom flex-center">
        <div className="emoji">
          <Image src={Img1} alt="emoji-icon" priority={false} loading="lazy" />
        </div>
        <span>
          {locale === "ar"
            ? mainData?.items?.find((item) => item.title === "copyright")?.value
            : mainData?.items?.find((item) => item.title === "copyright_en")
                ?.value}
        </span>
      </div>
      <ScrollTopButton />
    </div>
  );
};

export default Footer;
