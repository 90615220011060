import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_THEMES,
  GET_THEMES_SUCCESS,
  GET_THEMES_FAILURE,
} from "./actionTypes";

// ================== CATEGORIES ====================
export const getCategories = (payload) => {
  return {
    type: GET_CATEGORIES,
    payload,
  };
};
export const getCategoriesSuccess = (payload) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload,
  };
};
export const getCategoriesFailure = (payload) => {
  return {
    type: GET_CATEGORIES_FAILURE,
    payload,
  };
};
// ================== THEMES ====================
export const getThemes = (payload) => {
  return {
    type: GET_THEMES,
    payload,
  };
};
export const getThemesSuccess = (payload) => {
  return {
    type: GET_THEMES_SUCCESS,
    payload,
  };
};
export const getThemesFailure = (payload) => {
  return {
    type: GET_THEMES_FAILURE,
    payload,
  };
};
