import {
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAILURE,
  GET_FEATURES,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_FAILURE,
} from "./actionTypes";

// ================== CATEGORIES ====================
export const getPackages = (payload) => {
  return {
    type: GET_PACKAGES,
    payload,
  };
};
export const getPackagesSuccess = (payload) => {
  return {
    type: GET_PACKAGES_SUCCESS,
    payload,
  };
};
export const getPackagesFailure = (payload) => {
  return {
    type: GET_PACKAGES_FAILURE,
    payload,
  };
};
// ================== FEATURES  ====================
export const getFeatures = (payload) => {
  return {
    type: GET_FEATURES,
    payload,
  };
};
export const getFeaturesSuccess = (payload) => {
  return {
    type: GET_FEATURES_SUCCESS,
    payload,
  };
};
export const getFeaturesFailure = (payload) => {
  return {
    type: GET_FEATURES_FAILURE,
    payload,
  };
};
