import { HYDRATE } from "next-redux-wrapper";
import {
  GET_PORTAL_SECTION,
  GET_PORTAL_SECTION_SUCCESS,
  GET_PORTAL_SECTION_FAILURE,
  GET_POLICY,
  GET_POLICY_SUCCESS,
  GET_POLICY_FAILURE,
  GET_TERMS,
  GET_TERMS_SUCCESS,
  GET_TERMS_FAILURE,
} from "./actionTypes";

const initialState = {
  portalSection: [],
  policy: {},
  terms: {},
  error: "",
  loading: false,
};
const portalSection = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      for (const key in action.payload?.portalSection) {
        if (Object.hasOwnProperty.call(action.payload?.portalSection, key)) {
          const element = action.payload?.portalSection[key];
          element === "init" && delete action.payload?.portalSection[key];
        }
      }

      return { ...state, ...action.payload.portalSection };
    // ========== START : portalSection ===========

    case GET_PORTAL_SECTION:
      return {
        ...state,
        loading: true,
      };

    case GET_PORTAL_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        portalSection: action.payload,
      };

    case GET_PORTAL_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START : policy ===========

    case GET_POLICY:
      return {
        ...state,
        loading: true,
      };

    case GET_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        policy: action.payload,
      };

    case GET_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START : terms ===========

    case GET_TERMS:
      return {
        ...state,
        loading: true,
      };

    case GET_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        terms: action.payload,
      };

    case GET_TERMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default portalSection;
