// ==============================
export const GET_PORTAL_HELP_BLOGS = "GET_PORTAL_HELP_BLOGS";
export const GET_PORTAL_HELP_BLOGS_SUCCESS = "GET_PORTAL_HELP_BLOGS_SUCCESS";
export const GET_PORTAL_HELP_BLOGS_FAILURE = "GET_PORTAL_HELP_BLOGS_FAILURE";
// ==============================
// ==============================
export const GET_HELP_CATEGORIES_BY_TYPE = "GET_HELP_CATEGORIES_BY_TYPE";
export const GET_HELP_CATEGORIES_BY_TYPE_SUCCESS =
  "GET_HELP_CATEGORIES_BY_TYPE_SUCCESS";
export const GET_HELP_CATEGORIES_BY_TYPE_FAILURE =
  "GET_HELP_CATEGORIES_BY_TYPE_FAILURE";
// ==============================
// ==============================
export const GET_HELP_BLOGS_BY_CATEGORY = "GET_HELP_BLOGS_BY_CATEGORY";
export const GET_HELP_BLOGS_BY_CATEGORY_SUCCESS =
  "GET_HELP_BLOGS_BY_CATEGORY_SUCCESS";
export const GET_HELP_BLOGS_BY_CATEGORY_FAILURE =
  "GET_HELP_BLOGS_BY_CATEGORY_FAILURE";
// ==============================
// ==============================
export const GET_PORTAL_FAQS = "GET_PORTAL_FAQS";
export const GET_PORTAL_FAQS_SUCCESS = "GET_PORTAL_FAQS_SUCCESS";
export const GET_PORTAL_FAQS_FAILURE = "GET_PORTAL_FAQS_FAILURE";
// ==============================
// ==============================
export const GET_HELP_CATEGORIES = "GET_HELP_CATEGORIES";
export const GET_HELP_CATEGORIES_SUCCESS = "GET_HELP_CATEGORIES_SUCCESS";
export const GET_HELP_CATEGORIES_FAILURE = "GET_HELP_CATEGORIES_FAILURE";
// ==============================
// ==============================
// export const GET_HELP_BLOGS_BY_TYPE = "GET_HELP_BLOGS_BY_TYPE";
// export const GET_HELP_BLOGS_BY_TYPE_SUCCESS = "GET_HELP_BLOGS_BY_TYPE_SUCCESS";
// export const GET_HELP_BLOGS_BY_TYPE_FAILURE = "GET_HELP_BLOGS_BY_TYPE_FAILURE";
// ==============================
// ==============================
export const GET_HELP_BLOG = "GET_HELP_BLOG";
export const GET_HELP_BLOG_SUCCESS = "GET_HELP_BLOG_SUCCESS";
export const GET_HELP_BLOG_FAILURE = "GET_HELP_BLOG_FAILURE";
// ==============================
// ==============================
export const GET_HELP_CENTER_CONTENT_BY_ID = "GET_HELP_CENTER_CONTENT_BY_ID";
export const GET_HELP_CENTER_CONTENT_BY_ID_SUCCESS =
  "GET_HELP_CENTER_CONTENT_BY_ID_SUCCESS";
export const GET_HELP_CENTER_CONTENT_BY_ID_FAILURE =
  "GET_HELP_CENTER_CONTENT_BY_ID_FAILURE";
// ==============================
// ==============================
export const POST_TECHNICAL_SUPPORTS = "POST_TECHNICAL_SUPPORTS";
export const POST_TECHNICAL_SUPPORTS_SUCCESS =
  "POST_TECHNICAL_SUPPORTS_SUCCESS";
export const POST_TECHNICAL_SUPPORTS_FAILURE =
  "POST_TECHNICAL_SUPPORTS_FAILURE";
// ==============================
// ==============================
export const POST_PORTAL_RESERVATION = "POST_PORTAL_RESERVATION";
export const POST_PORTAL_RESERVATION_SUCCESS =
  "POST_PORTAL_RESERVATION_SUCCESS";
export const POST_PORTAL_RESERVATION_FAILURE =
  "POST_PORTAL_RESERVATION_FAILURE";
// ==============================
// ==============================
export const ADD_VOTE = "ADD_VOTE";
export const ADD_VOTE_SUCCESS = "ADD_VOTE_SUCCESS";
export const ADD_VOTE_FAILURE = "ADD_VOTE_FAILURE";
// ==============================
// ==============================
export const ADD_SUGGESTION = "ADD_SUGGESTION";
export const ADD_SUGGESTION_SUCCESS = "ADD_SUGGESTION_SUCCESS";
export const ADD_SUGGESTION_FAILURE = "ADD_SUGGESTION_FAILURE";
// ==============================
// ==============================
export const ADD_COMMENT_IN_SUGGESTION = "ADD_COMMENT_IN_SUGGESTION";
export const ADD_COMMENT_IN_SUGGESTION_SUCCESS =
  "ADD_COMMENT_IN_SUGGESTION_SUCCESS";
export const ADD_COMMENT_IN_SUGGESTION_FAILURE =
  "ADD_COMMENT_IN_SUGGESTION_FAILURE";
// ==============================
// ==============================
export const ADD_PORTAL_SUBSCRIBERS = "ADD_PORTAL_SUBSCRIBERS";
export const ADD_PORTAL_SUBSCRIBERS_SUCCESS = "ADD_PORTAL_SUBSCRIBERS_SUCCESS";
export const ADD_PORTAL_SUBSCRIBERS_FAILURE = "ADD_PORTAL_SUBSCRIBERS_FAILURE";
// ==============================
// ==============================
