import {
  ADD_BLOG_REVIEW,
  ADD_BLOG_REVIEW_FAILURE,
  ADD_BLOG_REVIEW_SUCCESS,
  DELETE_BLOG_REVIEW,
  DELETE_BLOG_REVIEW_FAILURE,
  DELETE_BLOG_REVIEW_SUCCESS,
  GET_BLOGS,
  GET_BLOGS_BY_ARCHIVE,
  GET_BLOGS_BY_ARCHIVE_FAILURE,
  GET_BLOGS_BY_ARCHIVE_SUCCESS,
  GET_BLOGS_BY_DATE,
  GET_BLOGS_BY_DATE_FAILURE,
  GET_BLOGS_BY_DATE_SUCCESS,
  GET_BLOGS_BY_TAG,
  GET_BLOGS_BY_TAG_FAILURE,
  GET_BLOGS_BY_TAG_SUCCESS,
  GET_BLOGS_CATEGORIES,
  GET_BLOGS_CATEGORIES_FAILURE,
  GET_BLOGS_CATEGORIES_SUCCESS,
  GET_BLOGS_CATEGORY_BY_SLUG,
  GET_BLOGS_CATEGORY_BY_SLUG_FAILURE,
  GET_BLOGS_CATEGORY_BY_SLUG_SUCCESS,
  GET_BLOGS_FAILURE,
  GET_BLOGS_SUCCESS,
  GET_BLOGS_TAGS,
  GET_BLOGS_TAGS_FAILURE,
  GET_BLOGS_TAGS_SUCCESS,
  GET_SINGLE_BLOG,
  GET_SINGLE_BLOG_FAILURE,
  GET_SINGLE_BLOG_SUCCESS,
  LOAD_MORE_BLOGS,
  LOAD_MORE_BLOGS_FAILURE,
  LOAD_MORE_BLOGS_SUCCESS,
} from "./actionTypes";

// ============= GET ALL BLOGS =============

export const getBlogs = (payload) => {
  return {
    type: GET_BLOGS,
    payload,
  };
};

export const getBlogsSuccess = (payload) => {
  return {
    type: GET_BLOGS_SUCCESS,
    payload,
  };
};

export const getBlogsFailure = (payload) => {
  return {
    type: GET_BLOGS_FAILURE,
    payload,
  };
};
// ============= GET ALL BLOGS BY ARCHIVE =============

export const getBlogsByArchive = (payload) => {
  return {
    type: GET_BLOGS_BY_ARCHIVE,
    payload,
  };
};

export const getBlogsByArchiveSuccess = (payload) => {
  return {
    type: GET_BLOGS_BY_ARCHIVE_SUCCESS,
    payload,
  };
};

export const getBlogsByArchiveFailure = (payload) => {
  return {
    type: GET_BLOGS_BY_ARCHIVE_FAILURE,
    payload,
  };
};
// ============= GET ALL BLOGS BY TAG =============

export const getBlogsByTag = (payload) => {
  return {
    type: GET_BLOGS_BY_TAG,
    payload,
  };
};

export const getBlogsByTagSuccess = (payload) => {
  return {
    type: GET_BLOGS_BY_TAG_SUCCESS,
    payload,
  };
};

export const getBlogsByTagFailure = (payload) => {
  return {
    type: GET_BLOGS_BY_TAG_FAILURE,
    payload,
  };
};
// ============= GET ALL BLOGS BY ARCHIVE =============

export const getBlogsByDate = (payload) => {
  return {
    type: GET_BLOGS_BY_DATE,
    payload,
  };
};

export const getBlogsByDateSuccess = (payload) => {
  return {
    type: GET_BLOGS_BY_DATE_SUCCESS,
    payload,
  };
};

export const getBlogsByDateFailure = (payload) => {
  return {
    type: GET_BLOGS_BY_DATE_FAILURE,
    payload,
  };
};
// ============= GET ALL BLOGS BY TAG =============

export const getBlogsCategories = (payload) => {
  return {
    type: GET_BLOGS_CATEGORIES,
    payload,
  };
};

export const getBlogsCategoriesSuccess = (payload) => {
  return {
    type: GET_BLOGS_CATEGORIES_SUCCESS,
    payload,
  };
};

export const getBlogsCategoriesFailure = (payload) => {
  return {
    type: GET_BLOGS_CATEGORIES_FAILURE,
    payload,
  };
};
// ============= GET ALL BLOGS BY TAG =============

export const getBlogsCategoryBySlug = (payload) => {
  return {
    type: GET_BLOGS_CATEGORY_BY_SLUG,
    payload,
  };
};

export const getBlogsCategoryBySlugSuccess = (payload) => {
  return {
    type: GET_BLOGS_CATEGORY_BY_SLUG_SUCCESS,
    payload,
  };
};

export const getBlogsCategoryBySlugFailure = (payload) => {
  return {
    type: GET_BLOGS_CATEGORY_BY_SLUG_FAILURE,
    payload,
  };
};
// =========== GET SINGLE BLOG =============
export const getSingleBlog = (payload) => {
  return {
    type: GET_SINGLE_BLOG,
    payload,
  };
};

export const getSingleBlogSuccess = (payload) => {
  return {
    type: GET_SINGLE_BLOG_SUCCESS,
    payload,
  };
};

export const getSingleBlogFailure = (payload) => {
  return {
    type: GET_SINGLE_BLOG_FAILURE,
    payload,
  };
};

// ================== GET BLOGS TAGS ===================

export const getBlogsTags = (payload) => {
  return {
    type: GET_BLOGS_TAGS,
    payload,
  };
};
export const getBlogsTagsSuccess = (payload) => {
  return {
    type: GET_BLOGS_TAGS_SUCCESS,
    payload,
  };
};

export const getBlogsTagsFailure = (payload) => {
  return {
    type: GET_BLOGS_TAGS_FAILURE,
    payload,
  };
};
// ================= ADD BLOG REVIEW =================
export const addBlogReview = (payload) => {
  return {
    type: ADD_BLOG_REVIEW,
    payload,
  };
};

export const addBlogReviewSuccess = (payload) => {
  return {
    type: ADD_BLOG_REVIEW_SUCCESS,
    payload,
  };
};

export const addBlogReviewFailure = (payload) => {
  return {
    type: ADD_BLOG_REVIEW_FAILURE,
    payload,
  };
};

export const deleteBlogReview = (payload) => {
  return {
    type: DELETE_BLOG_REVIEW,
    payload,
  };
};

export const deleteBlogReviewSuccess = (payload) => {
  return {
    type: DELETE_BLOG_REVIEW_SUCCESS,
    payload,
  };
};

export const deleteBlogReviewFailure = (payload) => {
  return {
    type: DELETE_BLOG_REVIEW_FAILURE,
    payload,
  };
};

export const loadMoreBlogs = (payload) => {
  return {
    type: LOAD_MORE_BLOGS,
    payload,
  };
};

export const loadMoreBlogsSuccess = (payload) => {
  return {
    type: LOAD_MORE_BLOGS_SUCCESS,
    payload,
  };
};

export const loadMoreBlogsFailure = (payload) => {
  return {
    type: LOAD_MORE_BLOGS_FAILURE,
    payload,
  };
};
