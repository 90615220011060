import {
  GET_PORTAL_SETTINGS,
  GET_PORTAL_SETTINGS_SUCCESS,
  GET_PORTAL_SETTINGS_FAILURE,
  GET_SEO_DATA,
  GET_SEO_DATA_SUCCESS,
  GET_SEO_DATA_FAILURE,
} from "./actionTypes";

// ================== PORTAL SETTINGS ====================
export const getPortalSettings = (payload) => {
  return {
    type: GET_PORTAL_SETTINGS,
    payload,
  };
};
export const getPortalSettingsSuccess = (payload) => {
  return {
    type: GET_PORTAL_SETTINGS_SUCCESS,
    payload,
  };
};
export const getPortalSettingsFailure = (payload) => {
  return {
    type: GET_PORTAL_SETTINGS_FAILURE,
    payload,
  };
};
// ================== SEO DATA ====================
export const getSeoData = (payload) => {
  return {
    type: GET_SEO_DATA,
    payload,
  };
};
export const getSeoDataSuccess = (payload) => {
  return {
    type: GET_SEO_DATA_SUCCESS,
    payload,
  };
};
export const getSeoDataFailure = (payload) => {
  return {
    type: GET_SEO_DATA_FAILURE,
    payload,
  };
};
