import { all } from "redux-saga/effects";
import blogsSaga from "./blogs/saga";
import authenticationSaga from "./auth/saga";
// import settingsSaga from "./settings/saga";
import portalSaga from "./portalSection/saga";
import packagesSaga from "./packages/saga";
import themesSaga from "./themes/saga";
import helpCenterSaga from "./helpCenter/saga";
import portalSettingsSaga from "./portalSettings/saga";

export default function* rootSaga() {
  yield all([
    blogsSaga(),
    authenticationSaga(),
    // settingsSaga(),
    portalSaga(),
    portalSettingsSaga(),
    packagesSaga(),
    themesSaga(),
    helpCenterSaga(),
  ]);
}
