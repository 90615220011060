import { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/future/image";
import dynamic from "next/dynamic";
import { parseCookies } from "nookies";
import { useRouter } from "next/router";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./styles/style.module.scss";
import { handleImageLink } from "@/helpers/functions";
// IMPORT Components
// import ConsultPopup from "@/components/Shared/ConsultPopup/ConsultPopup";
const ConsultPopup = dynamic(
  () => import("@/components/Shared/ConsultPopup/ConsultPopup"),
  {
    loading: () => <p>Loading...</p>,
  }
);
import Sidebar from "./Sidebar";
// IMPORT IMAGES
import Menuicon from "@/assets/svgs/menu.svg";
import MoonIcon from "@/assets/svgs/moon.svg";
import SunIcon from "@/assets/svgs/sun.svg";

const Header = ({ removeTopBar }) => {
  const { formatMessage } = useIntl();
  const { pathname, asPath, locale } = useRouter();
  const [showSidebar, setShowSidebar] = useState(false);
  const [showConsultPopUp, setShowConsultPopUp] = useState(false);
  const { portalSettings } = useSelector((state) => state.portalSettings);
  const handleToggle = () => {
    setShowConsultPopUp(!showConsultPopUp);
  };
  const themeSwitcher = () => {
    // set theme to cookies for next time
    const cookies = parseCookies();
    const theme = cookies.theme ? cookies.theme : "light";
    document.documentElement.setAttribute(
      "data-theme",
      theme === "light" ? "dark" : "light"
    );
    document.cookie = `theme=${
      theme === "light" ? "dark" : "light"
    }; path=/; max-age=2592000`;
  };

  // State for theme
  const [theme, setTheme] = useState("light");

  // Apply theme and read from cookies on component mount
  useEffect(() => {
    const cookies = parseCookies();
    const savedTheme = cookies.theme || "light";
    setTheme(savedTheme);
  }, []);

  // Update the theme in DOM
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const mainData = portalSettings?.find((item) => item?._id === "main");
  const NavItem = ({ href, label }) => {
    // const { pathname } = useRouter();
    return (
      <li>
        <Link href={href}>
          <a className={`${pathname === href ? "active" : ""}`}>
            <FormattedMessage id={label} />
          </a>
        </Link>
      </li>
    );
  };

  return (
    <>
      <header className={`${styles["main-head"]} main-head`}>
        <div className="bottombar">
          <Container>
            <div className="inner">
              <button
                className={`menu-btn ${removeTopBar ? "remove-topbar" : ""}`}
                type="button"
                onClick={() => setShowSidebar(true)}
                aria-label="menu"
              >
                <Menuicon fill="#fc6b14" />
              </button>
              <div className={`logo ${removeTopBar ? "remove-topbar" : ""} `}>
                <Link href="/">
                  <a>
                    <Image
                      src={handleImageLink(
                        mainData?.items?.find((item) => item.title === "logo")
                          ?.value
                      )}
                      alt="logo"
                      className="logo-light"
                      width={172}
                      height={52}
                      priority={false}
                      loading="lazy"
                    />
                    <Image
                      src={handleImageLink(
                        mainData?.items?.find(
                          (item) => item.title === "logo_dark"
                        )?.value
                      )}
                      alt="logo"
                      className="logo-dark"
                      width={172}
                      height={52}
                      priority={false}
                      loading="lazy"
                    />
                  </a>
                </Link>
              </div>
              <div className={`menu ${removeTopBar ? "remove-topbar" : ""} `}>
                <ul>
                  <NavItem href="/" label="home" />
                  <NavItem href="/help-center" label="helpCenter" />
                  <NavItem href="/blog" label="blog" />
                  {/* <NavItem href="/prices" label="Prices" /> */}
                  {/* <NavItem href="/guideline" label="guideline" /> */}
                </ul>
              </div>

              <div className="features">
                {locale === "ar" ? (
                  <Link href={asPath} locale="en" passHref>
                    <a className="language">EN</a>
                  </Link>
                ) : (
                  <Link href={asPath} locale="ar" passHref>
                    <a className="language">AR</a>
                  </Link>
                )}
                <div className="btnToggle desktop">
                  <label>
                    <input
                      type="checkbox"
                      name="check"
                      onChange={themeSwitcher}
                      aria-label="Toggle theme"
                    />
                    <div className="box">
                      <div className="ball"></div>

                      <div className="scenary">
                        <div className="sun">
                          <SunIcon />
                        </div>
                        <div className="moon">
                          <MoonIcon />
                        </div>
                      </div>
                    </div>
                  </label>
                </div>

                {/* <Link href="/login">
                <a className={`login ${removeTopBar ? "remove-topbar" : ""} `}>
                  <FormattedMessage id="login" />
                </a>
              </Link> */}

                <button
                  onClick={handleToggle}
                  type="button"
                  className="booking btn-md flex-center"
                  aria-label={formatMessage({ id: "bookAConsult" })}
                >
                  <FormattedMessage id="bookAConsult" />
                </button>
              </div>
            </div>
          </Container>
        </div>

        <div className={`mobileSidebar ${showSidebar ? "active" : ""} `}>
          <Sidebar setShowSidebar={setShowSidebar} />
        </div>

        {showConsultPopUp && (
          <ConsultPopup
            show={showConsultPopUp}
            setShow={setShowConsultPopUp}
            handleToggle={handleToggle}
          />
        )}
      </header>
    </>
  );
};

export default Header;
