export const getId = (obj) =>
  obj?.id || obj?._id || (typeof obj === "string" ? obj : undefined);

export const handleImageLink = (image) => {
  if (image?.includes("http")) {
    return image;
  } else {
    // eslint-disable-next-line no-undef
    return `${process.env.NEXT_PUBLIC_MAIN_URL}/${image}`;
  }
};

export const truncate = (text, maxLength) => {
  if (text?.length <= maxLength) {
    return text;
  }
  return text?.substring(0, maxLength) + "...";
};

export const getFullDate = (date, locale) => {
  if (!date) return;
  return new Date(date).toLocaleDateString(
    locale === "ar" ? "ar-EG" : "en-US",
    {
      month: "long",
      day: "numeric",
      year: "numeric",
    }
  );
};
