import { useCallback, useState } from "react";
import Link from "next/link";
import { parseCookies } from "nookies";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";
import ConsultPopup from "@/components/Shared/ConsultPopup/ConsultPopup";
// IMPORT IMAGES
import Closeicon from "@/assets/svgs/close.svg";
import Global from "@/assets/svgs/global.svg";
import MoonIcon from "@/assets/svgs/moon.svg";
import SunIcon from "@/assets/svgs/sun.svg";
const Sidebar = ({ setShowSidebar }) => {
  const { pathname, asPath } = useRouter();

  const [showConsultPopUp, setShowConsultPopUp] = useState(false);
  const handleToggle = useCallback(() => {
    setShowConsultPopUp((prev) => !prev);
  }, []);
  const themeSwitcher = () => {
    // set theme to cookies for next time
    const cookies = parseCookies();
    const theme = cookies.theme ? cookies.theme : "light";
    document.documentElement.setAttribute(
      "data-theme",
      theme === "light" ? "dark" : "light"
    );
    document.cookie = `theme=${
      theme === "light" ? "dark" : "light"
    }; path=/; max-age=2592000`;
  };

  const NavItem = ({ href, label }) => {
    // const { pathname } = useRouter();
    return (
      <li>
        <Link href={href}>
          <a className={`${pathname === href ? "active" : ""}`}>
            <FormattedMessage id={label} />
          </a>
        </Link>
      </li>
    );
  };

  return (
    <div className="inner">
      <div className="top">
        <div className="logo">
          <h3>
            <FormattedMessage id="Menu" />
          </h3>
        </div>
        <button
          className="close-btn"
          aria-label="Close Sidebar"
          // onClick={(prev) => setShowSidebar(!prev)}
          onClick={() => setShowSidebar((prev) => !prev)}
        >
          <Closeicon fill="#000" width="14" height="14" />
        </button>
      </div>

      <div className="menu-mobileMode">
        <ul>
          <NavItem href="/" label="home" />
          <NavItem href="/help-center" label="helpCenter" />
          <NavItem href="/blog" label="blog" />
          {/* <NavItem href="/prices" label="Prices" /> */}
          {/* <NavItem href="/guideline" label="guideline" /> */}
          {/* <NavItem href="/login" label="login" /> */}
        </ul>
      </div>
      <div className="favoriteLink">
        <div className="inner">
          <button
            onClick={handleToggle}
            aria-label="Book a Consultation"
            className="booking"
          >
            <FormattedMessage id="bookAConsult" />
          </button>
        </div>
      </div>

      <div className="languages">
        <div className="inner">
          <h3>
            <Global fill="#003087" />
            <FormattedMessage id="language" />
          </h3>
          <div className="choices">
            <Link href={asPath} locale="ar" passHref>
              <a className="language">عربي</a>
            </Link>
            <Link href={asPath} locale="en" passHref>
              <a className="language">English</a>
            </Link>
          </div>
        </div>
      </div>

      <div className="btnToggle mobile">
        <label>
          <input
            type="checkbox"
            name="check"
            aria-label="Toggle Dark Mode"
            onChange={themeSwitcher}
          />
          <div className="box">
            <div className="ball"></div>

            <div className="scenary">
              <div className="sun">
                <SunIcon />
              </div>
              <div className="moon">
                <MoonIcon />
              </div>
            </div>
          </div>
        </label>
      </div>
      {showConsultPopUp && (
        <ConsultPopup
          show={showConsultPopUp}
          setShow={setShowConsultPopUp}
          handleToggle={handleToggle}
        />
      )}
    </div>
  );
};
export default Sidebar;
