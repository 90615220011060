import AOS from "aos";
import Head from "next/head";
import { useRouter } from "next/router";
// import { GoogleTagManager } from "@next/third-parties/google";
import { IntlProvider } from "react-intl";
import { Toaster } from "react-hot-toast";
import { useEffect, useMemo, useState } from "react";
import SSRProvider from "react-bootstrap/SSRProvider";
import TopBarProgress from "react-topbar-progress-indicator";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "aos/dist/aos.css";
import "swiper/css";

import "../styles/main.scss";

import Loading from "@/components/Shared/Loading";

// import { END } from "redux-saga";
import { wrapper } from "@/store/index";
import { useSelector } from "react-redux";
import Header from "@/components/Layout/Header/Index";
import Footer from "@/components/Layout/Footer/Index";
// import { getPortalSettings } from "@/store/actions";
// import { parseCookies } from "nookies";

const languages = {
  // eslint-disable-next-line no-undef
  ar: require("../src/content/languages/ar.json"),
  // eslint-disable-next-line no-undef
  en: require("../src/content/languages/en.json"),
};

function MyApp({ Component, pageProps }) {
  const [Progress, setProgress] = useState(false);
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const { portalSettings } = useSelector((state) => state.portalSettings);
  TopBarProgress.config({
    barThickness: 3,
    barColors: {
      0: "#38003C",
      0.5: "#fc6b14",
      1.0: "#e74c3c",
    },
  });

  useEffect(() => {
    const handleStart = (url) => {
      url !== router.pathname ? setLoading(true) : setLoading(false);
    };
    const handleComplete = () => setLoading(false);

    router.events.on("routeChangeStart", () => {
      handleStart();
      setProgress(true);
    });
    router.events.on("routeChangeComplete", () => {
      handleComplete();
      setProgress(false);
    });
    router.events.on("routeChangeError", handleComplete);
  }, [router]);

  const { locale, defaultLocale } = useRouter();
  const messages = languages[locale];
  const dir = locale === "ar" ? "rtl" : "ltr";

  // Direction & AnimationOnScroll
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "mobile",
    });
    document.documentElement.dir = dir;
    document.body.style.direction = dir;
    document.body.setAttribute("dir", dir);
  }, [dir]);

  // ============= SEO =============
  const mainSettings = useMemo(
    () => portalSettings?.find((item) => item?._id === "main"),
    [portalSettings]
  );
  const seoSettings = useMemo(
    () => portalSettings?.find((item) => item?._id === "seo"),
    [portalSettings]
  );

  const favicon = useMemo(
    () => mainSettings?.items?.find((item) => item.title === "favicon")?.value,
    [portalSettings]
  );

  const siteNameEn = useMemo(
    () =>
      mainSettings?.items?.find((item) => item.title === "siteNameEn")?.value,
    [portalSettings]
  );

  const siteNameAr = useMemo(
    () =>
      mainSettings?.items?.find((item) => item.title === "siteNameAr")?.value,
    [portalSettings]
  );

  const description_ar = useMemo(
    () =>
      mainSettings?.items?.find((item) => item.title === "description_ar")
        ?.value,
    [portalSettings]
  );

  const description_en = useMemo(
    () =>
      mainSettings?.items?.find((item) => item.title === "description_en")
        ?.value,
    [portalSettings]
  );

  const keywords = useMemo(
    () => seoSettings?.items?.find((item) => item.title === "keywords")?.value,
    [portalSettings]
  );

  const metaTitle = useMemo(
    () => seoSettings?.items?.find((item) => item.title === "metaTitle")?.value,
    [portalSettings]
  );

  const metaDescription = useMemo(
    () =>
      seoSettings?.items?.find((item) => item.title === "metaDescription")
        ?.value,
    [portalSettings]
  );

  return (
    <>
      <Head>
        <title>{locale === "ar" ? siteNameAr : siteNameEn}</title>
        <link rel="shortcut icon" href={favicon} />
        <link rel="icon" href={favicon} />
        <meta
          name="description"
          content={locale === "ar" ? description_ar : description_en}
          key="description"
        />
        <meta name="keywords" content={keywords} />
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      {/* <GoogleTagManager gtmId="GTM-XYZ" /> */}
      {Progress && <TopBarProgress />}
      <Loading loading={loading} />
      <IntlProvider
        messages={messages}
        defaultLocale={defaultLocale}
        locale={locale}
      >
        <SSRProvider>
          <div className="wrap">
            <Toaster
              position="top-left"
              containerClassName="tj-toaster"
              reverseOrder
            />
            <Header />
            <Component {...pageProps} />
            <Footer />
          </div>
        </SSRProvider>
      </IntlProvider>
    </>
  );
}

// MyApp.getInitialProps = wrapper.getInitialAppProps((store) => {
//   return async () => {
//     store.dispatch(getPortalSettings({ cookies: {} }));
//     store.dispatch(END);
//     await store.sagaTask.toPromise();

//     return {
//       // pageProps,
//     };
//   };
// });

export default wrapper.withRedux(MyApp);
