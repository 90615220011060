// ==============================
export const GET_PORTAL_SETTINGS = "GET_PORTAL_SETTINGS";
export const GET_PORTAL_SETTINGS_SUCCESS = "GET_PORTAL_SETTINGS_SUCCESS";
export const GET_PORTAL_SETTINGS_FAILURE = "GET_PORTAL_SETTINGS_FAILURE";
// ==============================
// ==============================
export const GET_SEO_DATA = "GET_SEO_DATA";
export const GET_SEO_DATA_SUCCESS = "GET_SEO_DATA_SUCCESS";
export const GET_SEO_DATA_FAILURE = "GET_SEO_DATA_FAILURE";
// ==============================
