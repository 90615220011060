import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { setCookie } from "nookies";
import router from "next/router";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { toastErrorMessage } from "@/components/Shared/StatusErrors";
import { getId } from "@/helpers/functions";
import {
  forgotPasswordFailure,
  forgotPasswordSuccess,
  postUserLoginFailure,
  postUserLoginSuccess,
  postUserRegisterFailure,
  postUserRegisterSuccess,
  resendVerifyOTPFailure,
  resendVerifyOTPSuccess,
  updatePasswordFailure,
  updatePasswordSuccess,
  verifyOTPFailure,
  verifyOTPSuccess,
} from "./actions";
import {
  FORGOT_PASSWORD,
  POST_USER_LOGIN,
  POST_USER_REGISTER,
  RESEND_VERIFY_OTP,
  UPDATE_PASSWORD,
  VERIFY_OTP,
} from "./actionTypes";
import {
  forgotPasswordApi,
  postUserLoginApi,
  postUserRegisterApi,
  resendVerifyOTPApi,
  updatePasswordApi,
  verifyOTPApi,
} from "@/api/authentication";

function* postUserLogin({ payload }) {
  try {
    const { data, success } = yield call(postUserLoginApi, payload);

    yield put(postUserLoginSuccess(data?.user));
    if (success) {
      setCookie(null, "token", data?.token, { path: "/" });
      setCookie(null, "userId", getId(data?.user), { path: "/" });
      localStorage.setItem("token", data?.token);
    }
  } catch (error) {
    yield put(postUserLoginFailure(JSON.stringify(error)));
    toastErrorMessage({ error: error?.response?.data?.message });
  }
}

// ===========================================
function* postUserRegister({ payload }) {
  try {
    const { data, success } = yield call(postUserRegisterApi, payload);

    yield put(postUserRegisterSuccess(data?.storeAdmin));
    if (success) {
      setCookie(null, "token", data?.token, { path: "/" });
      setCookie(null, "userId", getId(data?.storeAdmin), { path: "/" });
      localStorage.setItem("token", data?.token);
    }
  } catch (error) {
    yield put(postUserRegisterFailure(JSON.stringify(error)));
    if (
      error?.response?.data?.errors?.find((error) => error?.param === "email")
    ) {
      toast.error(<FormattedMessage id="Email already exists" />, {
        duration: 5000,
      });
    } else if (
      error?.response?.data?.errors?.find((error) => error?.param === "phone")
    ) {
      toast.error(<FormattedMessage id="Phone already exists" />, {
        duration: 5000,
      });
    } else {
      toastErrorMessage({ error: error?.response?.data?.message });
    }
  }
}
// ===========================================
function* forgotPassword({ payload }) {
  try {
    const { data, success } = yield call(forgotPasswordApi, payload);
    yield put(forgotPasswordSuccess(data));
    if (success) {
      toast.success(<FormattedMessage id={data?.msg} />, {
        duration: 8000,
      });

      router.push("/otp");

      // if (payload.setIsSentSuccess) {
      //   // to start the counter to show the resend button
      //   payload.setIsSentSuccess(true);
      // }
    }
  } catch (error) {
    yield put(forgotPasswordFailure(JSON.stringify(error)));
    toastErrorMessage({ error: error?.response?.data?.message });
  }
}
// ===========================================
function* verifyOTP({ payload }) {
  try {
    const { data, success } = yield call(verifyOTPApi, payload);
    yield put(verifyOTPSuccess(data));
    if (success) {
      // localStorage.removeItem("saveEmailTemporarily");
      router.push("/reset-password");
    }
  } catch (error) {
    yield put(verifyOTPFailure(JSON.stringify(error)));
    toast.error(error?.message, {
      duration: 8000,
    });
  }
}
// ===========================================
function* resendVerifyOTP({ payload }) {
  try {
    const { data, success } = yield call(resendVerifyOTPApi, payload);
    yield put(resendVerifyOTPSuccess(data));
    if (success) {
      toast.success(<FormattedMessage id={data} />, {
        duration: 8000,
      });

      if (payload.setIsSentSuccess) {
        // to start the counter to show the resend button
        payload.setIsSentSuccess(true);
      }
    }
  } catch (error) {
    yield put(resendVerifyOTPFailure(JSON.stringify(error)));
    toast.error(error?.message, {
      duration: 8000,
    });
  }
}
//=====================================================
function* updatePassword({ payload }) {
  try {
    const { data, success } = yield call(updatePasswordApi, payload);
    yield put(updatePasswordSuccess(data));
    if (success) {
      toast.success(data.msg, {
        duration: 8000,
      });
      localStorage.removeItem("saveEmailTemporarily");
    }
  } catch (error) {
    yield put(updatePasswordFailure(JSON.stringify(error)));
    toast.error(error?.message, {
      duration: 8000,
    });
  }
}
//=====================================================
export function* watchPostUserLogin() {
  yield takeEvery(POST_USER_LOGIN, postUserLogin);
}
export function* watchPostUserRegister() {
  yield takeEvery(POST_USER_REGISTER, postUserRegister);
}
export function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
}
export function* watchVerifyOTP() {
  yield takeLatest(VERIFY_OTP, verifyOTP);
}
export function* watchResendVerifyOTP() {
  yield takeLatest(RESEND_VERIFY_OTP, resendVerifyOTP);
}
export function* watchUpdatePassword() {
  yield takeLatest(UPDATE_PASSWORD, updatePassword);
}

// =====================================================
//=====================================================
function* authenticationSaga() {
  yield all([fork(watchPostUserLogin)]);
  yield all([fork(watchPostUserRegister)]);
  yield all([fork(watchForgotPassword)]);
  yield all([fork(watchVerifyOTP)]);
  yield all([fork(watchResendVerifyOTP)]);
  yield all([fork(watchUpdatePassword)]);
}
export default authenticationSaga;
