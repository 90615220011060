import axios from "axios";
import { parseCookies } from "nookies";

const server = ({ ctx, cookies }) => {
  const headers = {};
  let token;

  if (typeof window !== "undefined" && cookies.token !== undefined) {
    token = cookies.token;
  } else {
    const cookies = parseCookies(ctx);
    token = cookies.token;
  }

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers,
  });
};

export default server;
