import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getFeaturesFailure,
  getFeaturesSuccess,
  getPackagesFailure,
  getPackagesSuccess,
} from "./actions";
import { GET_FEATURES, GET_PACKAGES } from "./actionTypes";
import { getFeaturesApi, getPackagesApi } from "@/api/packages";

function* getPackagesSaga({ payload }) {
  try {
    const { data } = yield call(getPackagesApi, payload);
    yield put(getPackagesSuccess(data?.packages));
  } catch (error) {
    yield put(getPackagesFailure(JSON.stringify(error)));
  }
}
function* getFeaturesSaga({ payload }) {
  try {
    const { data } = yield call(getFeaturesApi, payload);
    yield put(getFeaturesSuccess(data?.features));
  } catch (error) {
    yield put(getFeaturesFailure(JSON.stringify(error)));
  }
}

export function* watchPackagesSaga() {
  yield takeEvery(GET_PACKAGES, getPackagesSaga);
}
export function* watchFeaturesSaga() {
  yield takeEvery(GET_FEATURES, getFeaturesSaga);
}

function* packagesSaga() {
  yield all([fork(watchPackagesSaga)]);
  yield all([fork(watchFeaturesSaga)]);
}
export default packagesSaga;
