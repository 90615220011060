import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { createWrapper } from "next-redux-wrapper";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

// eslint-disable-next-line no-undef
const IS_PRODUCTION = process.env.NODE_ENV === "production";
function bindMiddleware(middleware) {
  if (!IS_PRODUCTION) {
    // eslint-disable-next-line no-undef
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
}

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, bindMiddleware([sagaMiddleware]));
  store.sagaTask = sagaMiddleware?.run(rootSaga);
  return store;
}

export default configureStore;
export const wrapper = createWrapper(configureStore, {
  debug: false,
});
