import { HYDRATE } from "next-redux-wrapper";
import {
  GET_BLOGS,
  GET_BLOGS_SUCCESS,
  GET_BLOGS_FAILURE,
  GET_SINGLE_BLOG,
  GET_SINGLE_BLOG_SUCCESS,
  GET_SINGLE_BLOG_FAILURE,
  GET_BLOGS_TAGS,
  GET_BLOGS_TAGS_SUCCESS,
  GET_BLOGS_TAGS_FAILURE,
  GET_BLOGS_BY_TAG,
  GET_BLOGS_BY_TAG_SUCCESS,
  GET_BLOGS_BY_TAG_FAILURE,
  ADD_BLOG_REVIEW,
  ADD_BLOG_REVIEW_SUCCESS,
  ADD_BLOG_REVIEW_FAILURE,
  DELETE_BLOG_REVIEW,
  DELETE_BLOG_REVIEW_SUCCESS,
  DELETE_BLOG_REVIEW_FAILURE,
  GET_BLOGS_CATEGORIES,
  GET_BLOGS_CATEGORIES_SUCCESS,
  GET_BLOGS_CATEGORIES_FAILURE,
  GET_BLOGS_CATEGORY_BY_SLUG,
  GET_BLOGS_CATEGORY_BY_SLUG_SUCCESS,
  GET_BLOGS_CATEGORY_BY_SLUG_FAILURE,
  GET_BLOGS_BY_ARCHIVE,
  GET_BLOGS_BY_ARCHIVE_FAILURE,
  GET_BLOGS_BY_ARCHIVE_SUCCESS,
  GET_BLOGS_BY_DATE,
  GET_BLOGS_BY_DATE_FAILURE,
  GET_BLOGS_BY_DATE_SUCCESS,
  LOAD_MORE_BLOGS,
  LOAD_MORE_BLOGS_SUCCESS,
  LOAD_MORE_BLOGS_FAILURE,
} from "./actionTypes";

const initialState = {
  blogs: [],
  blogsByCategory: [],
  blogsByTag: [],
  singleBlog: {},
  categories: [],
  tags: [],
  archive: [],
  metadata: {},
  error: null,
  loading: false,
};
const blogs = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      for (const key in action.payload?.blogs) {
        if (Object.hasOwnProperty.call(action.payload?.blogs, key)) {
          const element = action.payload?.blogs[key];
          element === "init" && delete action.payload?.blogs[key];
        }
      }
      return { ...state, ...action.payload.blogs };
    // ============================
    case GET_BLOGS:
      return {
        ...state,
        loading: true,
      };

    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        blogs: action.payload?.blogs || [],
        metadata: action.payload?.metadata || {},
      };

    case GET_BLOGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================
    // ============================
    case GET_BLOGS_BY_DATE:
      return {
        ...state,
        loading: true,
      };

    case GET_BLOGS_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        blogs: action.payload?.blogs || [],
        metadata: action.payload?.metadata || {},
      };

    case GET_BLOGS_BY_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================
    // ============================

    case LOAD_MORE_BLOGS:
      return {
        ...state,
        loading: true,
      };

    case LOAD_MORE_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.payload.blogs,
        metadata: action.payload?.metadata,
      };

    case LOAD_MORE_BLOGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ============================
    // ============================
    case GET_BLOGS_BY_ARCHIVE:
      return {
        ...state,
        loading: true,
      };

    case GET_BLOGS_BY_ARCHIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        archive: action.payload,
      };

    case GET_BLOGS_BY_ARCHIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ============================
    case GET_BLOGS_BY_TAG:
      return {
        ...state,
        loading: true,
      };

    case GET_BLOGS_BY_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        blogs: action.payload,
      };

    case GET_BLOGS_BY_TAG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // =============================
    // ============================
    case GET_BLOGS_CATEGORIES:
      return {
        ...state,
        loading: true,
      };

    case GET_BLOGS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };

    case GET_BLOGS_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ============================
    case GET_BLOGS_CATEGORY_BY_SLUG:
      return {
        ...state,
        loading: true,
      };

    case GET_BLOGS_CATEGORY_BY_SLUG_SUCCESS:
      return {
        ...state,
        loading: false,
        blogs: action.payload,
      };

    case GET_BLOGS_CATEGORY_BY_SLUG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // =============================
    case GET_SINGLE_BLOG:
      return {
        ...state,
        loading: true,
      };

    case GET_SINGLE_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        singleBlog: action.payload,
      };

    case GET_SINGLE_BLOG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ============================
    case GET_BLOGS_TAGS:
      return {
        ...state,
        loading: true,
      };

    case GET_BLOGS_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: action.payload,
      };

    case GET_BLOGS_TAGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ============================
    case ADD_BLOG_REVIEW:
      return {
        ...state,
        loading: true,
      };
    case ADD_BLOG_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        singleBlog: action.payload,
      };

    case ADD_BLOG_REVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ======================

    case DELETE_BLOG_REVIEW:
      return {
        ...state,
        loading: true,
      };

    case DELETE_BLOG_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        singleBlog: {
          ...state.singleBlog,
          comments: state.singleBlog.comments.filter(
            (review) => review?._id !== action.payload?.commentId
          ),
        },
      };

    case DELETE_BLOG_REVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default blogs;
