import {
  GET_HELP_CATEGORIES_BY_TYPE,
  GET_HELP_CATEGORIES_BY_TYPE_SUCCESS,
  GET_HELP_CATEGORIES_BY_TYPE_FAILURE,
  POST_TECHNICAL_SUPPORTS,
  POST_TECHNICAL_SUPPORTS_SUCCESS,
  POST_TECHNICAL_SUPPORTS_FAILURE,
  GET_HELP_CENTER_CONTENT_BY_ID,
  GET_HELP_CENTER_CONTENT_BY_ID_SUCCESS,
  GET_HELP_CENTER_CONTENT_BY_ID_FAILURE,
  GET_PORTAL_HELP_BLOGS,
  GET_PORTAL_HELP_BLOGS_SUCCESS,
  GET_PORTAL_HELP_BLOGS_FAILURE,
  ADD_VOTE,
  ADD_VOTE_SUCCESS,
  ADD_VOTE_FAILURE,
  ADD_SUGGESTION,
  ADD_SUGGESTION_SUCCESS,
  ADD_SUGGESTION_FAILURE,
  GET_HELP_CATEGORIES,
  GET_HELP_CATEGORIES_SUCCESS,
  GET_HELP_CATEGORIES_FAILURE,
  GET_HELP_BLOG,
  GET_HELP_BLOG_SUCCESS,
  GET_HELP_BLOG_FAILURE,
  ADD_COMMENT_IN_SUGGESTION,
  ADD_COMMENT_IN_SUGGESTION_SUCCESS,
  ADD_COMMENT_IN_SUGGESTION_FAILURE,
  GET_PORTAL_FAQS,
  GET_PORTAL_FAQS_SUCCESS,
  GET_PORTAL_FAQS_FAILURE,
  ADD_PORTAL_SUBSCRIBERS,
  ADD_PORTAL_SUBSCRIBERS_SUCCESS,
  ADD_PORTAL_SUBSCRIBERS_FAILURE,
  POST_PORTAL_RESERVATION,
  POST_PORTAL_RESERVATION_SUCCESS,
  POST_PORTAL_RESERVATION_FAILURE,
  GET_HELP_BLOGS_BY_CATEGORY,
  GET_HELP_BLOGS_BY_CATEGORY_SUCCESS,
  GET_HELP_BLOGS_BY_CATEGORY_FAILURE,
} from "./actionTypes";

// ================== Portal Help Blogs ====================
export const getPortalHelpBlogs = (payload) => {
  return {
    type: GET_PORTAL_HELP_BLOGS,
    payload,
  };
};
export const getPortalHelpBlogsSuccess = (payload) => {
  return {
    type: GET_PORTAL_HELP_BLOGS_SUCCESS,
    payload,
  };
};
export const getPortalHelpBlogsFailure = (payload) => {
  return {
    type: GET_PORTAL_HELP_BLOGS_FAILURE,
    payload,
  };
};
// ================== GET PORTAL FAQS ====================
export const getPortalFaqs = (payload) => {
  return {
    type: GET_PORTAL_FAQS,
    payload,
  };
};
export const getPortalFaqsSuccess = (payload) => {
  return {
    type: GET_PORTAL_FAQS_SUCCESS,
    payload,
  };
};
export const getPortalFaqsFailure = (payload) => {
  return {
    type: GET_PORTAL_FAQS_FAILURE,
    payload,
  };
};
// ================== GET HELP BLOGS BY CATEGORY ====================
export const getHelpBlogsByCategory = (payload) => {
  return {
    type: GET_HELP_BLOGS_BY_CATEGORY,
    payload,
  };
};
export const getHelpBlogsByCategorySuccess = (payload) => {
  return {
    type: GET_HELP_BLOGS_BY_CATEGORY_SUCCESS,
    payload,
  };
};
export const getHelpBlogsByCategoryFailure = (payload) => {
  return {
    type: GET_HELP_BLOGS_BY_CATEGORY_FAILURE,
    payload,
  };
};
// ================== GET HELP CATEGORIES BY TYPE ====================
export const getHelpCategoriesByType = (payload) => {
  return {
    type: GET_HELP_CATEGORIES_BY_TYPE,
    payload,
  };
};
export const getHelpCategoriesByTypeSuccess = (payload) => {
  return {
    type: GET_HELP_CATEGORIES_BY_TYPE_SUCCESS,
    payload,
  };
};
export const getHelpCategoriesByTypeFailure = (payload) => {
  return {
    type: GET_HELP_CATEGORIES_BY_TYPE_FAILURE,
    payload,
  };
};
// ================== General Settings ====================
export const getHelpCategories = (payload) => {
  return {
    type: GET_HELP_CATEGORIES,
    payload,
  };
};
export const getHelpCategoriesSuccess = (payload) => {
  return {
    type: GET_HELP_CATEGORIES_SUCCESS,
    payload,
  };
};
export const getHelpCategoriesFailure = (payload) => {
  return {
    type: GET_HELP_CATEGORIES_FAILURE,
    payload,
  };
};

// ================== SUGGESTIONS ====================
export const getHelpBlog = (payload) => {
  return {
    type: GET_HELP_BLOG,
    payload,
  };
};
export const getHelpBlogSuccess = (payload) => {
  return {
    type: GET_HELP_BLOG_SUCCESS,
    payload,
  };
};
export const getHelpBlogFailure = (payload) => {
  return {
    type: GET_HELP_BLOG_FAILURE,
    payload,
  };
};
// ================== GET HELP CENTER CONTENT BY ID ====================
export const getHelpCenterContentById = (payload) => {
  return {
    type: GET_HELP_CENTER_CONTENT_BY_ID,
    payload,
  };
};
export const getHelpCenterContentByIdSuccess = (payload) => {
  return {
    type: GET_HELP_CENTER_CONTENT_BY_ID_SUCCESS,
    payload,
  };
};
export const getHelpCenterContentByIdFailure = (payload) => {
  return {
    type: GET_HELP_CENTER_CONTENT_BY_ID_FAILURE,
    payload,
  };
};
// ================== POST TECHNICAL SUPPORT ====================
export const postTechnicalSupports = (payload) => {
  return {
    type: POST_TECHNICAL_SUPPORTS,
    payload,
  };
};
export const postTechnicalSupportsSuccess = (payload) => {
  return {
    type: POST_TECHNICAL_SUPPORTS_SUCCESS,
    payload,
  };
};
export const postTechnicalSupportsFailure = (payload) => {
  return {
    type: POST_TECHNICAL_SUPPORTS_FAILURE,
    payload,
  };
};
// ================== POST PORTAL RESERVATION ====================
export const postPortalReservation = (payload) => {
  return {
    type: POST_PORTAL_RESERVATION,
    payload,
  };
};
export const postPortalReservationSuccess = (payload) => {
  return {
    type: POST_PORTAL_RESERVATION_SUCCESS,
    payload,
  };
};
export const postPortalReservationFailure = (payload) => {
  return {
    type: POST_PORTAL_RESERVATION_FAILURE,
    payload,
  };
};
// ================== ADD VOTE ====================
export const addVote = (payload) => {
  return {
    type: ADD_VOTE,
    payload,
  };
};
export const addVoteSuccess = (payload) => {
  return {
    type: ADD_VOTE_SUCCESS,
    payload,
  };
};
export const addVoteFailure = (payload) => {
  return {
    type: ADD_VOTE_FAILURE,
    payload,
  };
};
// ================== ADD SUGGESTION ====================
export const addSuggestion = (payload) => {
  return {
    type: ADD_SUGGESTION,
    payload,
  };
};
export const addSuggestionSuccess = (payload) => {
  return {
    type: ADD_SUGGESTION_SUCCESS,
    payload,
  };
};
export const addSuggestionFailure = (payload) => {
  return {
    type: ADD_SUGGESTION_FAILURE,
    payload,
  };
};
// ================== ADD SUGGESTION ====================
export const addCommentInSuggestion = (payload) => {
  return {
    type: ADD_COMMENT_IN_SUGGESTION,
    payload,
  };
};
export const addCommentInSuggestionSuccess = (payload) => {
  return {
    type: ADD_COMMENT_IN_SUGGESTION_SUCCESS,
    payload,
  };
};
export const addCommentInSuggestionFailure = (payload) => {
  return {
    type: ADD_COMMENT_IN_SUGGESTION_FAILURE,
    payload,
  };
};
// ================== ADD PORTAL SUBSCRIBERS ====================
export const addPortalSubscribers = (payload) => {
  return {
    type: ADD_PORTAL_SUBSCRIBERS,
    payload,
  };
};
export const addPortalSubscribersSuccess = (payload) => {
  return {
    type: ADD_PORTAL_SUBSCRIBERS_SUCCESS,
    payload,
  };
};
export const addPortalSubscribersFailure = (payload) => {
  return {
    type: ADD_PORTAL_SUBSCRIBERS_FAILURE,
    payload,
  };
};
