import { HYDRATE } from "next-redux-wrapper";
import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_THEMES,
  GET_THEMES_SUCCESS,
  GET_THEMES_FAILURE,
} from "./actionTypes";

const initialState = {
  themes: [],
  categories: [],
  error: "",
  loading: false,
};
const themes = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      for (const key in action.payload?.themes) {
        if (Object.hasOwnProperty.call(action.payload?.themes, key)) {
          const element = action.payload?.themes[key];
          element === "init" && delete action.payload?.themes[key];
        }
      }

      return { ...state, ...action.payload.themes };
    // ========== START : CATEGORIES ===========

    case GET_CATEGORIES:
      return {
        ...state,
        loading: true,
      };

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };

    case GET_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START : THEMES ===========

    case GET_THEMES:
      return {
        ...state,
        loading: true,
      };

    case GET_THEMES_SUCCESS:
      return {
        ...state,
        loading: false,
        themes: action.payload,
      };

    case GET_THEMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default themes;
