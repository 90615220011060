import server from "./server";

export const getPackagesApi = async ({ cookies }) => {
  const response = await server({ cookies }).get(`/packages`);
  return response.data;
};
export const getFeaturesApi = async ({ cookies }) => {
  const response = await server({ cookies }).get(`/features/getFeaturesByType`);
  return response.data;
};
