import server from "./server";

export const getBlogsApi = async ({ cookies, page, limit }) => {
  const response = await server({ cookies }).get(
    `/blogs?page=${page}&limit=${limit}`
  );
  return response.data;
};
export const getBlogsByDateApi = async ({ cookies, date }) => {
  const response = await server({ cookies }).get(`/blogs?date=${date}`);
  return response.data;
};
export const getBlogsByTagApi = async ({ cookies, slug }) => {
  const response = await server({ cookies }).get(
    `/blogs/getBlogsByTag/${encodeURIComponent(slug)}`
  );
  return response.data;
};
export const getBlogsCategoriesApi = async ({ cookies }) => {
  const response = await server({ cookies }).get(`/blogCategories`);
  return response.data;
};
export const getBlogsCategoryBySlugApi = async ({ cookies, slug }) => {
  const response = await server({ cookies }).get(
    `/blogCategories/getCategoryBySlug/${encodeURIComponent(slug)}`
  );
  return response.data;
};
export const getSingleBlogApi = async ({ cookies, slug }) => {
  const response = await server({ cookies }).get(
    `/blogs/getBlogBySlug/${encodeURIComponent(slug)}`
  );
  return response.data;
};
export const getBlogsTagsApi = async ({ cookies }) => {
  const response = await server({ cookies }).get(`/blogs/getAllTag`);
  return response.data;
};
export const getBlogsArchive = async ({ cookies }) => {
  const response = await server({ cookies }).get(`/blogs/getBlogsByDate`);
  return response.data;
};

export const addBlogReviewApi = async ({ cookies, data, id }) => {
  const response = await server({ cookies }).post(
    `/blogs/addComment/${id}`,
    data
  );
  return response.data;
};
export const deleteBlogReviewApi = async ({ cookies, blogId, commentId }) => {
  const response = await server({ cookies }).delete(
    `/blogs/deleteComment/${blogId}/${commentId}`
  );
  return response.data;
};
