// ==============================
export const GET_PORTAL_SECTION = "GET_PORTAL_SECTION";
export const GET_PORTAL_SECTION_SUCCESS = "GET_PORTAL_SECTION_SUCCESS";
export const GET_PORTAL_SECTION_FAILURE = "GET_PORTAL_SECTION_FAILURE";
// ==============================
// ==============================
export const GET_POLICY = "GET_POLICY";
export const GET_POLICY_SUCCESS = "GET_POLICY_SUCCESS";
export const GET_POLICY_FAILURE = "GET_POLICY_FAILURE";
// ==============================
// ==============================
export const GET_TERMS = "GET_TERMS";
export const GET_TERMS_SUCCESS = "GET_TERMS_SUCCESS";
export const GET_TERMS_FAILURE = "GET_TERMS_FAILURE";
// ==============================
