export const GET_BLOGS = "GET_BLOGS";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAILURE = "GET_BLOGS_FAILURE";
// ==============================
// ==============================
export const GET_BLOGS_BY_ARCHIVE = "GET_BLOGS_BY_ARCHIVE";
export const GET_BLOGS_BY_ARCHIVE_SUCCESS = "GET_BLOGS_BY_ARCHIVE_SUCCESS";
export const GET_BLOGS_BY_ARCHIVE_FAILURE = "GET_BLOGS_BY_ARCHIVE_FAILURE";
// ==============================
// ==============================
export const GET_BLOGS_BY_DATE = "GET_BLOGS_BY_DATE";
export const GET_BLOGS_BY_DATE_SUCCESS = "GET_BLOGS_BY_DATE_SUCCESS";
export const GET_BLOGS_BY_DATE_FAILURE = "GET_BLOGS_BY_DATE_FAILURE";
// ==============================
// ==============================
export const GET_BLOGS_CATEGORIES = "GET_BLOGS_CATEGORIES";
export const GET_BLOGS_CATEGORIES_SUCCESS = "GET_BLOGS_CATEGORIES_SUCCESS";
export const GET_BLOGS_CATEGORIES_FAILURE = "GET_BLOGS_CATEGORIES_FAILURE";
// ==============================
// ==============================
export const GET_BLOGS_CATEGORY_BY_SLUG = "GET_BLOGS_CATEGORY_BY_SLUG";
export const GET_BLOGS_CATEGORY_BY_SLUG_SUCCESS =
  "GET_BLOGS_CATEGORY_BY_SLUG_SUCCESS";
export const GET_BLOGS_CATEGORY_BY_SLUG_FAILURE =
  "GET_BLOGS_CATEGORY_BY_SLUG_FAILURE";
// ==============================
// ==============================
export const GET_BLOGS_BY_TAG = "GET_BLOGS_BY_TAG";
export const GET_BLOGS_BY_TAG_SUCCESS = "GET_BLOGS_BY_TAG_SUCCESS";
export const GET_BLOGS_BY_TAG_FAILURE = "GET_BLOGS_BY_TAG_FAILURE";
// ==============================
// ==============================
export const GET_SINGLE_BLOG = "GET_SINGLE_BLOG";
export const GET_SINGLE_BLOG_SUCCESS = "GET_SINGLE_BLOG_SUCCESS";
export const GET_SINGLE_BLOG_FAILURE = "GET_SINGLE_BLOG_FAILURE";
// ==============================
// ==============================
export const GET_BLOGS_TAGS = "GET_BLOGS_TAGS";
export const GET_BLOGS_TAGS_SUCCESS = "GET_BLOGS_TAGS_SUCCESS";
export const GET_BLOGS_TAGS_FAILURE = "GET_BLOGS_TAGS_FAILURE";
// ==============================
// ==============================
export const ADD_BLOG_REVIEW = "ADD_BLOG_REVIEW";
export const ADD_BLOG_REVIEW_SUCCESS = "ADD_BLOG_REVIEW_SUCCESS";
export const ADD_BLOG_REVIEW_FAILURE = "ADD_BLOG_REVIEW_FAILURE";
// ==============================
// ==============================
export const DELETE_BLOG_REVIEW = "DELETE_BLOG_REVIEW";
export const DELETE_BLOG_REVIEW_SUCCESS = "DELETE_BLOG_REVIEW_SUCCESS";
export const DELETE_BLOG_REVIEW_FAILURE = "DELETE_BLOG_REVIEW_FAILURE";
// ==============================
// ==============================

export const LOAD_MORE_BLOGS = "LOAD_MORE_BLOGS";
export const LOAD_MORE_BLOGS_SUCCESS = "LOAD_MORE_BLOGS_SUCCESS";
export const LOAD_MORE_BLOGS_FAILURE = "LOAD_MORE_BLOGS_FAILURE";
