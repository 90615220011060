import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getPolicyFailure,
  getPolicySuccess,
  getPortalSectionFailure,
  getPortalSectionSuccess,
  getTermsFailure,
  getTermsSuccess,
} from "./actions";
import { GET_POLICY, GET_PORTAL_SECTION, GET_TERMS } from "./actionTypes";
import {
  getPolicyApi,
  getPortalSectionsApi,
  getTermsApi,
} from "@/api/portalSection";

function* getPortalSectionSaga({ payload }) {
  try {
    const { data } = yield call(getPortalSectionsApi, payload);
    yield put(getPortalSectionSuccess(data?.portalSections));
  } catch (error) {
    yield put(getPortalSectionFailure(JSON.stringify(error)));
  }
}
// ==============================================
function* getPolicySaga({ payload }) {
  try {
    const { data } = yield call(getPolicyApi, payload);
    yield put(getPolicySuccess(data?.portalSection));
  } catch (error) {
    yield put(getPolicyFailure(JSON.stringify(error)));
  }
}
// ==============================================
function* getTermsSaga({ payload }) {
  try {
    const { data } = yield call(getTermsApi, payload);
    yield put(getTermsSuccess(data?.portalSection));
  } catch (error) {
    yield put(getTermsFailure(JSON.stringify(error)));
  }
}
// ==============================================

export function* watchPortalSectionSaga() {
  yield takeEvery(GET_PORTAL_SECTION, getPortalSectionSaga);
}
export function* watchPolicySaga() {
  yield takeEvery(GET_POLICY, getPolicySaga);
}
export function* watchTermsSaga() {
  yield takeEvery(GET_TERMS, getTermsSaga);
}

function* portalSaga() {
  yield all([fork(watchPortalSectionSaga)]);
  yield all([fork(watchPolicySaga)]);
  yield all([fork(watchTermsSaga)]);
}
export default portalSaga;
