import { Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { postPortalReservation } from "@/store/actions";
import styles from "./style.module.scss";

// const urlPattern = new RegExp(
//   "^(https?:\\/\\/)?" + // protocol
//     "((([a-zA-Z0-9$\\-_@.&+!*\"'(),]|(%[0-9a-fA-F]{2}))+)(:[0-9]{1,5})?)" + // username, password, hostname
//     "(\\/([a-zA-Z0-9$\\-_@.&+!*\"'(),]|(%[0-9a-fA-F]{2}))+)*" + // path
//     "(\\/?)" + // optional trailing slash
//     "(\\?([a-zA-Z0-9$\\-_@.&+!*\"'(),=%])*?)?" + // query string
//     "(#([a-zA-Z0-9$\\-_@.&+!*\"'(),]|[%\\[\\]]|[%0-9a-fA-F]{2})*)?$" // fragment
// );
// Old RegExp
const urlPattern = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-zA-Z0-9$\\-_@.&+!*\"'(),]|(%[0-9a-fA-F]{2}))+)(:[0-9]{1,5})?)" + // username, password, hostname
    "(\\/([a-zA-Z0-9$\\-_@.&+!*\"'(),]|(%[0-9a-fA-F]{2}))+)*" + // path
    "(\\/?)" + // optional trailing slash
    "(\\?([a-zA-Z0-9$\\-_@.&+!*\"'(),]|[%\\[\\]]|[%0-9a-fA-F]{2})*)?" + // query string
    "(#([a-zA-Z0-9$\\-_@.&+!*\"'(),]|[%\\[\\]]|[%0-9a-fA-F]{2})*)?$" // fragment
);

const ConsultPopup = ({ handleToggle, show, setShow }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // Check if there are no errors before proceeding
    dispatch(
      postPortalReservation({
        formData: data,
        toast,
        reset,
        setShow,
        cookies: {},
        formatMessage,
      })
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleToggle}
      size="lg"
      className={`${styles["modal-consult"]} modal-consult`}
    >
      <Modal.Header closeButton>
        <h3 className="title">
          <FormattedMessage id="bookAConsult" />
        </h3>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="first-page">
            <div className="first-group">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col xs={12} md={6} lg={6} xl={6}>
                    <div className="input-div">
                      <label>
                        <FormattedMessage id="name" />
                      </label>
                      <input
                        type="text"
                        maxLength="64"
                        {...register("name", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors?.name && (
                      <p className="error">
                        <FormattedMessage id="requiredMerchantName" />
                      </p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={6}>
                    <div className="input-div">
                      <label>
                        <FormattedMessage id="email" />
                      </label>

                      <input
                        type="email"
                        {...register("email", {
                          required: true,
                          pattern:
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        })}
                        onBlur={() => {
                          const emailValue = watch("email");
                          if (
                            !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                              emailValue
                            )
                          ) {
                            setError("email", { type: "pattern" });
                          } else {
                            clearErrors("email");
                          }
                        }}
                      />
                    </div>
                    {errors?.email && (
                      <p className="error">
                        {errors.email.type === "required" ? (
                          <FormattedMessage id="requiredEmail" />
                        ) : (
                          <FormattedMessage id="invalidEmail" />
                        )}
                      </p>
                    )}
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <div className="input-div">
                      <label>
                        <FormattedMessage id="phone" />
                      </label>
                      <input
                        type="tel"
                        {...register("phone", {
                          required: true,
                          validate: (value) => {
                            const phoneWithoutCountryCode = value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            if (phoneWithoutCountryCode.length !== 10) {
                              return "phoneNumberDigits";
                            }
                            return true;
                          },
                        })}
                        onKeyPress={(event) => {
                          if (!/[0-9+]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onInput={(event) => {
                          const input = event.target;
                          input.value = input.value.replace(/[^0-9+]/g, "");
                        }}
                      />
                    </div>

                    {errors?.phone && (
                      <p className="error">
                        {
                          <FormattedMessage
                            id={errors?.phone?.message || "requiredPhone"}
                          />
                        }
                      </p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={6}>
                    <div className="input-div">
                      <label className="optional">
                        <FormattedMessage id="storeUrlOptional" />
                      </label>
                      <input
                        type="text"
                        maxLength="225"
                        {...register("storeUrl", {
                          validate: (value) => {
                            if (value && !urlPattern.test(value)) {
                              return formatMessage({ id: "invalidUrl" });
                            }
                            return true;
                          },
                        })}
                      />
                    </div>
                    {errors.storeUrl && (
                      <p className="error">{errors.storeUrl.message}</p>
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={6}>
                    <div className="input-div">
                      <label>
                        <FormattedMessage id="storeType" />
                      </label>
                      <input
                        type="text"
                        maxLength="64"
                        {...register("storeType", { required: true })}
                      />
                    </div>
                    {errors?.storeType && (
                      <p className="error">
                        <FormattedMessage id="requiredStoreType" />
                      </p>
                    )}
                  </Col>
                  <div className="btn-div">
                    <button className="btn-md">
                      <FormattedMessage id="Send" />
                    </button>
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConsultPopup;
