export const POST_USER_LOGIN = "POST_USER_LOGIN";
export const POST_USER_LOGIN_SUCCESS = "POST_USER_LOGIN_SUCCESS";
export const POST_USER_LOGIN_FAILURE = "POST_USER_LOGIN_FAILURE";
//============================
//============================
export const POST_USER_REGISTER = "POST_USER_REGISTER";
export const POST_USER_REGISTER_SUCCESS = "POST_USER_REGISTER_SUCCESS";
export const POST_USER_REGISTER_FAILURE = "POST_USER_REGISTER_FAILURE";
//============================
//============================
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
//============================
//============================
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";
//============================
//============================
export const RESEND_VERIFY_OTP = "RESEND_VERIFY_OTP";
export const RESEND_VERIFY_OTP_SUCCESS = "RESEND_VERIFY_OTP_SUCCESS";
export const RESEND_VERIFY_OTP_FAILURE = "RESEND_VERIFY_OTP_FAILURE";
//============================
//============================
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";
//============================
//============================
