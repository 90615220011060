import {
  GET_PORTAL_SECTION,
  GET_PORTAL_SECTION_SUCCESS,
  GET_PORTAL_SECTION_FAILURE,
  GET_TERMS,
  GET_TERMS_SUCCESS,
  GET_TERMS_FAILURE,
  GET_POLICY,
  GET_POLICY_SUCCESS,
  GET_POLICY_FAILURE,

} from "./actionTypes";

// ================== CATEGORIES ====================
export const getPortalSection = (payload) => {
  return {
    type: GET_PORTAL_SECTION,
    payload,
  };
};
export const getPortalSectionSuccess = (payload) => {
  return {
    type: GET_PORTAL_SECTION_SUCCESS,
    payload,
  };
};
export const getPortalSectionFailure = (payload) => {
  return {
    type: GET_PORTAL_SECTION_FAILURE,
    payload,
  };
};
// ================== Terms  ====================
export const getTerms = (payload) => {
  return {
    type: GET_TERMS,
    payload,
  };
};
export const getTermsSuccess = (payload) => {
  return {
    type: GET_TERMS_SUCCESS,
    payload,
  };
};
export const getTermsFailure = (payload) => {
  return {
    type: GET_TERMS_FAILURE,
    payload,
  };
};
// ================== POLICY ====================
export const getPolicy = (payload) => {
  return {
    type: GET_POLICY,
    payload,
  };
};
export const getPolicySuccess = (payload) => {
  return {
    type: GET_POLICY_SUCCESS,
    payload,
  };
};
export const getPolicyFailure = (payload) => {
  return {
    type: GET_POLICY_FAILURE,
    payload,
  };
};
