import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  POST_USER_LOGIN,
  POST_USER_LOGIN_FAILURE,
  POST_USER_LOGIN_SUCCESS,
  POST_USER_REGISTER,
  POST_USER_REGISTER_FAILURE,
  POST_USER_REGISTER_SUCCESS,
  RESEND_VERIFY_OTP,
  RESEND_VERIFY_OTP_FAILURE,
  RESEND_VERIFY_OTP_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
} from "./actionTypes";

// =====================================
// ========= START : GET LOGIN =========
// =====================================
export const postUserLogin = (payload) => {
  return {
    type: POST_USER_LOGIN,
    payload,
  };
};
export const postUserLoginSuccess = (payload) => {
  return {
    type: POST_USER_LOGIN_SUCCESS,
    payload,
  };
};
export const postUserLoginFailure = (payload) => {
  return {
    type: POST_USER_LOGIN_FAILURE,
    payload,
  };
};
// =====================================
// ========= START : GET REGISTER =========
// =====================================
export const postUserRegister = (payload) => {
  return {
    type: POST_USER_REGISTER,
    payload,
  };
};
export const postUserRegisterSuccess = (payload) => {
  return {
    type: POST_USER_REGISTER_SUCCESS,
    payload,
  };
};
export const postUserRegisterFailure = (payload) => {
  return {
    type: POST_USER_REGISTER_FAILURE,
    payload,
  };
};
// =====================================
// ========= START : FORGOT PASSWORD =========
// =====================================
export const forgotPassword = (payload) => {
  return {
    type: FORGOT_PASSWORD,
    payload,
  };
};
export const forgotPasswordSuccess = (payload) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload,
  };
};
export const forgotPasswordFailure = (payload) => {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload,
  };
};
// =====================================
// ========= START : VERIFY OTP =========
// =====================================
export const verifyOTP = (payload) => {
  return {
    type: VERIFY_OTP,
    payload,
  };
};
export const verifyOTPSuccess = (payload) => {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload,
  };
};
export const verifyOTPFailure = (payload) => {
  return {
    type: VERIFY_OTP_FAILURE,
    payload,
  };
};
// =====================================
// ========= START : RESEND VERIFY OTP =========
// =====================================
export const resendVerifyOTP = (payload) => {
  return {
    type: RESEND_VERIFY_OTP,
    payload,
  };
};
export const resendVerifyOTPSuccess = (payload) => {
  return {
    type: RESEND_VERIFY_OTP_SUCCESS,
    payload,
  };
};
export const resendVerifyOTPFailure = (payload) => {
  return {
    type: RESEND_VERIFY_OTP_FAILURE,
    payload,
  };
};
// =====================================
// ========= START : UPDATE PASSWORD =========
// =====================================
export const updatePassword = (payload) => {
  return {
    type: UPDATE_PASSWORD,
    payload,
  };
};
export const updatePasswordSuccess = (payload) => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload,
  };
};
export const updatePasswordFailure = (payload) => {
  return {
    type: UPDATE_PASSWORD_FAILURE,
    payload,
  };
};
