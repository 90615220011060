import { HYDRATE } from "next-redux-wrapper";
import {
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAILURE,
  GET_FEATURES,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_FAILURE,
} from "./actionTypes";

const initialState = {
  packages: [],
  features: [],
  error: "",
  loading: false,
};
const packages = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      for (const key in action.payload?.packages) {
        if (Object.hasOwnProperty.call(action.payload?.packages, key)) {
          const element = action.payload?.packages[key];
          element === "init" && delete action.payload?.packages[key];
        }
      }

      return { ...state, ...action.payload.packages };
    // ========== START : PACKAGES ===========

    case GET_PACKAGES:
      return {
        ...state,
        loading: true,
      };

    case GET_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        packages: action.payload,
      };

    case GET_PACKAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_FEATURES:
      return {
        ...state,
        loading: true,
      };

    case GET_FEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
        features: action.payload,
      };

    case GET_FEATURES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default packages;
