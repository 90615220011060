import { HYDRATE } from "next-redux-wrapper";
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  POST_USER_LOGIN,
  POST_USER_LOGIN_FAILURE,
  POST_USER_LOGIN_SUCCESS,
  POST_USER_REGISTER,
  POST_USER_REGISTER_FAILURE,
  POST_USER_REGISTER_SUCCESS,
  RESEND_VERIFY_OTP,
  RESEND_VERIFY_OTP_FAILURE,
  RESEND_VERIFY_OTP_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
} from "./actionTypes";

const initialState = {
  user: {},
  isLoggedIn: false,
  loading: false,
  error: null,
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      for (const key in action.payload?.authentication) {
        if (Object.hasOwnProperty.call(action.payload?.authentication, key)) {
          const element = action.payload?.authentication[key];
          element === "init" && delete action.payload?.authentication[key];
        }
      }
      return { ...state, ...action.payload.authentication };
    // ========== START : GET LOGIN ===========
    case POST_USER_LOGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case POST_USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload?.user,
        isLoggedIn: true,
        error: null,
      };
    case POST_USER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START : GET REGISTER ===========
    case POST_USER_REGISTER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case POST_USER_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload?.storeAdmin,
        isLoggedIn: true,
        error: null,
      };
    case POST_USER_REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START : FORGOT PASSWORD ===========
    case FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        // user: action.payload?.storeAdmin,
        // isLoggedIn: true,
        error: null,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ========== START : VERIFY OTP ===========
    case VERIFY_OTP:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        // user: action.payload?.storeAdmin,
        // isLoggedIn: true,
        error: null,
      };
    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================
    // ========== START : RESEND VERIFY OTP ===========
    case RESEND_VERIFY_OTP:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RESEND_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case RESEND_VERIFY_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ============================================
    // ========== START : UPDATE PASSWORD ===========
    case UPDATE_PASSWORD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================

    default:
      return state;
  }
};

export default authentication;
