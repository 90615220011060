import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  addCommentInSuggestionFailure,
  addCommentInSuggestionSuccess,
  addPortalSubscribersFailure,
  addPortalSubscribersSuccess,
  addSuggestionFailure,
  addSuggestionSuccess,
  addVoteFailure,
  addVoteSuccess,
  getHelpCategoriesByTypeFailure,
  getHelpCategoriesByTypeSuccess,
  getPortalFaqsFailure,
  getPortalFaqsSuccess,
  getPortalHelpBlogsFailure,
  getPortalHelpBlogsSuccess,
  postTechnicalSupportsFailure,
  postTechnicalSupportsSuccess,
  getHelpBlogSuccess,
  getHelpBlogFailure,
  postPortalReservationSuccess,
  postPortalReservationFailure,
  getHelpBlogsByCategorySuccess,
  getHelpBlogsByCategoryFailure,
} from "./actions";
import {
  ADD_COMMENT_IN_SUGGESTION,
  ADD_PORTAL_SUBSCRIBERS,
  ADD_SUGGESTION,
  ADD_VOTE,
  GET_HELP_CATEGORIES_BY_TYPE,
  GET_PORTAL_FAQS,
  GET_PORTAL_HELP_BLOGS,
  GET_HELP_BLOG,
  POST_TECHNICAL_SUPPORTS,
  POST_PORTAL_RESERVATION,
  GET_HELP_BLOGS_BY_CATEGORY,
} from "./actionTypes";
import {
  addCommentInSuggestionApi,
  addPortalSubscribersApi,
  addSuggestionApi,
  addVoteApi,
  getHelpCategoriesByTypeApi,
  getPortalFaqsApi,
  postTechnicalSupportsApi,
  getHelpBlogApi,
  postPortalReservationApi,
  getHelpBlogsByTypeApi,
  getHelpBlogsByCategoryApi,
} from "@/api/helpCenter";
import { toastErrorMessage } from "@/components/Shared/StatusErrors";
import { FormattedMessage } from "react-intl";
function* getPortalHelpBlogs({ payload }) {
  try {
    const { data } = yield call(getHelpBlogsByTypeApi, payload);
    yield put(getPortalHelpBlogsSuccess(data?.helpCenterTopics));
  } catch (error) {
    yield put(getPortalHelpBlogsFailure(JSON.stringify(error)));
  }
}
function* getHelpBlogsByCategory({ payload }) {
  try {
    const { data } = yield call(getHelpBlogsByCategoryApi, payload);
    yield put(getHelpBlogsByCategorySuccess(data?.helpCenterTopics));
  } catch (error) {
    yield put(getHelpBlogsByCategoryFailure(JSON.stringify(error)));
  }
}

function* getHelpCategoriesByTypeSaga({ payload }) {
  try {
    const { data } = yield call(getHelpCategoriesByTypeApi, payload);
    yield put(getHelpCategoriesByTypeSuccess(data?.helpCenterCategories));
  } catch (error) {
    yield put(getHelpCategoriesByTypeFailure(JSON.stringify(error)));
  }
}
function* getPortalFaqsSaga({ payload }) {
  try {
    const { data } = yield call(getPortalFaqsApi, payload);
    yield put(getPortalFaqsSuccess(data?.portalFaqs));
  } catch (error) {
    yield put(getPortalFaqsFailure(JSON.stringify(error)));
  }
}
function* getHelpBlogSaga({ payload }) {
  try {
    const { data } = yield call(getHelpBlogApi, payload);
    yield put(getHelpBlogSuccess(data?.helpCenterTopic));
  } catch (error) {
    console.log(JSON.stringify(error));
    yield put(getHelpBlogFailure(JSON.stringify(error)));
  }
}

function* postTechnicalSupportsSaga({ payload }) {
  try {
    const { data, success } = yield call(postTechnicalSupportsApi, payload);
    yield put(postTechnicalSupportsSuccess(data));
    if (success) {
      yield payload.reset();
      if (payload.setActive) {
        yield payload.setActive(false);
      }
      yield payload?.toast?.success(
        data?.msg?.router?.locale ||
          payload?.formatMessage({
            id: "submittedSuccessfully",
          })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(postTechnicalSupportsFailure(JSON.stringify(error)));
    toastErrorMessage({ error: error?.response?.data });
  }
}
function* postPortalReservationSaga({ payload }) {
  try {
    const { data, success } = yield call(postPortalReservationApi, payload);
    yield put(postPortalReservationSuccess(data));
    if (success) {
      yield payload.reset();
      if (payload.setShow) {
        yield payload.setShow(false);
      }
      yield payload?.toast?.success(
        payload?.formatMessage({
          id: "sentSuccessfully",
        })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(postPortalReservationFailure(JSON.stringify(error)));
    toastErrorMessage({ error: error?.response?.data });
  }
}
function* addVote({ payload }) {
  try {
    const { data, success } = yield call(addVoteApi, payload);
    yield put(addVoteSuccess(data?.portalHelpBlogs));
    if (success) {
      yield payload?.toast.success(<FormattedMessage id="votedSuccessfully" />);
    }
  } catch (error) {
    console.log(JSON.stringify(error));
    const statusCode = error?.response?.status; // Get the status code from the error response
    if (statusCode === 422) {
      payload?.toast.error(error.response?.data?.errors?.msg?.[payload.locale]);
    } else {
      toastErrorMessage({ error: error?.response?.data });
    }
    yield put(addVoteFailure(JSON.stringify(error)));
  }
}
function* addSuggestion({ payload }) {
  try {
    const { data, success } = yield call(addSuggestionApi, payload);
    yield put(addSuggestionSuccess(data?.portalHelpBlog));
    if (success) {
      yield payload.reset();
      yield payload?.toast.success(<FormattedMessage id="addedSuccessfully" />);
    }
  } catch (error) {
    // console.log("error: ", { ...error });
    yield put(addSuggestionFailure(JSON.stringify(error)));
    toastErrorMessage({ error: error?.response?.data });
  }
}
function* addCommentInSuggestion({ payload }) {
  try {
    const { data, success } = yield call(addCommentInSuggestionApi, payload);
    yield put(addCommentInSuggestionSuccess(data?.blog));
    if (success) {
      yield payload.reset();
      yield payload?.toast.success(<FormattedMessage id="commentAdded" />);
    }
  } catch (error) {
    console.log(error);
    yield put(addCommentInSuggestionFailure(error));
  }
}
function* addPortalSubscribers({ payload }) {
  try {
    const { data, success } = yield call(addPortalSubscribersApi, payload);
    yield put(addPortalSubscribersSuccess(data?.portalFaq));
    if (success) {
      yield payload.reset();
      yield payload?.toast.success(
        <FormattedMessage id="subscribedSuccessfully" />
      );
    }
  } catch (error) {
    console.log(error);
    yield put(addPortalSubscribersFailure(error));
  }
}

export function* watchPortalHelpBlogsSaga() {
  yield takeEvery(GET_PORTAL_HELP_BLOGS, getPortalHelpBlogs);
}
export function* watchHelpBlogsByCategorySaga() {
  yield takeEvery(GET_HELP_BLOGS_BY_CATEGORY, getHelpBlogsByCategory);
}
export function* watchGeneralSettingsSaga() {
  yield takeEvery(GET_HELP_CATEGORIES_BY_TYPE, getHelpCategoriesByTypeSaga);
}
export function* watchPortalFaqsSaga() {
  yield takeEvery(GET_PORTAL_FAQS, getPortalFaqsSaga);
}
export function* watchHelpBlogSaga() {
  yield takeEvery(GET_HELP_BLOG, getHelpBlogSaga);
}
export function* watchPostTechnicalSupportsSaga() {
  yield takeLatest(POST_TECHNICAL_SUPPORTS, postTechnicalSupportsSaga);
}
export function* watchPostPortalReservationSaga() {
  yield takeLatest(POST_PORTAL_RESERVATION, postPortalReservationSaga);
}
export function* watchAddVoteSaga() {
  yield takeLatest(ADD_VOTE, addVote);
}
export function* watchAddSuggestionSaga() {
  yield takeLatest(ADD_SUGGESTION, addSuggestion);
}
export function* watchAddCommentInSuggestionSaga() {
  yield takeLatest(ADD_COMMENT_IN_SUGGESTION, addCommentInSuggestion);
}
export function* watchAddPortalSubscribersSaga() {
  yield takeLatest(ADD_PORTAL_SUBSCRIBERS, addPortalSubscribers);
}

function* helpCenterSaga() {
  yield all([fork(watchPortalHelpBlogsSaga)]);
  yield all([fork(watchHelpBlogsByCategorySaga)]);
  yield all([fork(watchPortalFaqsSaga)]);
  yield all([fork(watchGeneralSettingsSaga)]);
  yield all([fork(watchHelpBlogSaga)]);
  yield all([fork(watchPostTechnicalSupportsSaga)]);
  yield all([fork(watchPostPortalReservationSaga)]);
  yield all([fork(watchAddVoteSaga)]);
  yield all([fork(watchAddSuggestionSaga)]);
  yield all([fork(watchAddCommentInSuggestionSaga)]);
  yield all([fork(watchAddPortalSubscribersSaga)]);
}
export default helpCenterSaga;
