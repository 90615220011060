import { toast } from "react-hot-toast";
import En from "../../content/languages/en.json";
import Ar from "../../content/languages/ar.json";

export const toastErrorMessage = ({
  error,
  locale = "ar",
  // autoClose = 2000,
}) => {
  // console.log("000", error);
  const code = String(error?.statusCode || error?.code);

  if (code.includes("50") && code !== "500")
    window.location.href = `/error/${code}`;

  if (document?.documentElement?.lang) locale = document.documentElement.lang;

  const langFile = locale === "ar" ? Ar : En;

  const t = (txt) => langFile?.[txt];

  let message =
    error?.errors?.length > 0 && typeof error?.errors !== "string"
      ? error?.errors
          ?.map(
            (e) =>
              `${e?.param ? `(${t(e?.param) || e?.param}) : ` : ""}${e?.msg}`
          )
          ?.join(".\n ")
      : (error?.errors?.length > 0 && t(error?.errors)) ||
        t(error?.message) ||
        t(error?.statusCode) ||
        `${error || t("error")}`;

  if (message?.length > 120) message = message?.slice(0, 120) + " ...";

  if (error?.statusCode === 500 && locale === "ar") message = "حدث خطا بالشبكة";
  if (error?.statusCode === 403) message = t("unauthorizedPleaseLogin");
  // console.log("error", error || t("error"));
  toast.error(<div dir="ltr">{message}</div>);
};
